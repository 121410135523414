import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import ProductMulti from '../../../models/ProductMulti';
import ProductMatrixCell from '../ProductMatrixCell';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import ProductMultiPropertyList from '../ProductMultiPropertyList';
import ProductImageModel from '../../../models/ProductImage';

const LEVEL_FOUR = 3;
const LEVEL_THREE = 2;
const LEVEL_TWO = 1;
const LEVEL_ONE = 0;

function createPropertyElement(property, element) {
  return { [property]: element };
}

@observer
export class ProductMultiMatrix extends Component {
  handleProductQuantity = (id, qty) => {
    const { handleProductQuantity } = this.props;
    handleProductQuantity(id, qty);
  };

  getProductCellRow = (currentPropertyElement, propertyElements, cellCount) => {
    const { multi } = this.props;

    const cells = propertyElements.map((propertyElement) => {
      const activePropertyElementMap = {
        ...currentPropertyElement,
        ...propertyElement,
      };

      const childProduct = multi.findChildWithProperties(
        activePropertyElementMap
      );

      return (
        <ProductMatrixCell
          key={uniqueId('Cell-')}
          product={childProduct}
          handleProductQuantity={this.handleProductQuantity}
          cellCount={cellCount}
        />
      );
    });

    return <div className="ProductMultiMatrix__product-cells">{cells}</div>;
  };

  getCellStyle = (cellCount) => {
    const { uiStore } = this.props;
    let width = 100;

    if ((cellCount > 5 && !uiStore.isDesktop) || cellCount > 10) {
      width = 78;
    }

    return {
      width: width,
    };
  };

  renderProductPropertyList = (accountedProperties) => {
    const { multi } = this.props;
    const property = accountedProperties[LEVEL_ONE];

    return (
      <ProductMultiPropertyList
        property={property}
        multi={multi}
        onRenderQuantityInput={(childProduct) => {
          return (
            <ProductMatrixCell
              key={childProduct?.productCode ?? 0}
              className="ProductMultiPropertyList__quantity-input"
              product={childProduct}
              handleProductQuantity={this.handleProductQuantity}
              cellCount={1}
              noPopover
            />
          );
        }}
      />
    );
  };

  renderTwoDimensions = (accountedProperties, currentPropertyElement = {}) => {
    const property_one = accountedProperties[LEVEL_ONE];
    const property_two = accountedProperties[LEVEL_TWO];
    const cellElements = [];
    const cellCount = property_two.elements.length;

    const headers = property_two.elements.map((element) => {
      cellElements.push(createPropertyElement(property_two.id, element.id));
      return (
        <div
          key={element.id}
          className="ProductMultiMatrix__second-level-header"
          style={this.getCellStyle(cellCount)}
        >
          {property_two.name}: {element.name}
        </div>
      );
    });

    const elements = property_one.elements.map((element) => {
      const propertyElement = createPropertyElement(
        property_one.id,
        element.id
      );

      return (
        <Row key={element.id} className="ProductMultiMatrix__first-level">
          <Col md={2} className="ProductMultiMatrix__first-level-header">
            <div>
              {property_one.name}: {element.name}
            </div>
          </Col>
          <Col md={10} className="ProductMultiMatrix__cells">
            {this.getProductCellRow(
              {
                ...propertyElement,
                ...currentPropertyElement,
              },
              cellElements,
              cellCount
            )}
          </Col>
        </Row>
      );
    });

    return (
      <>
        <Row className="ProductMultiMatrix__second-level">
          <Col md={2} className="ProductMultiMatrix__second-level" />
          <Col md={10} className="ProductMultiMatrix__second-level">
            <div className="ProductMultiMatrix__second-level-headers">
              {headers}
            </div>
          </Col>
        </Row>
        {elements}
      </>
    );
  };

  renderThreeDimensions = (
    accountedProperties,
    currentPropertyElement = {}
  ) => {
    const property = accountedProperties[LEVEL_THREE];

    return property.elements.map((element) => {
      const propertyElement = createPropertyElement(property.id, element.id);
      return (
        <Row key={element.id} className="ProductMultiMatrix__third-level">
          <Col md={12} className="ProductMultiMatrix__third-level-header">
            {property.name}: {element.name}
          </Col>
          <Col md={12} className="ProductMultiMatrix__two-dimensions">
            {this.renderTwoDimensions(accountedProperties, {
              ...propertyElement,
              ...currentPropertyElement,
            })}
          </Col>
        </Row>
      );
    });
  };

  renderFourDimensions = (accountedProperties) => {
    const property = accountedProperties[LEVEL_FOUR];

    return property.elements.map((element) => {
      return (
        <Fragment key={element.id}>
          <Row key={element.id} className="ProductMultiMatrix__fourth-level">
            <Col className="ProductMultiMatrix__fourth-level-header">
              {property.name}: {element.name}
            </Col>
          </Row>
          {this.renderThreeDimensions(
            accountedProperties,
            createPropertyElement(property.id, element.id)
          )}
        </Fragment>
      );
    });
  };

  render() {
    const { multi, configStore } = this.props;
    let matrix = null;
    let accountedProperties = multi.getMultiElementProperties();

    if (configStore.productPage.matrixDimensionSorting) {
      accountedProperties = accountedProperties.sort(
        (a, b) => b.elements.length - a.elements.length
      );
    }

    switch (accountedProperties.length) {
      case 4:
        matrix = this.renderFourDimensions(accountedProperties);
        break;
      case 3:
        matrix = this.renderThreeDimensions(accountedProperties);
        break;
      case 2:
        matrix = this.renderTwoDimensions(accountedProperties);
        break;
      default:
        matrix = this.renderProductPropertyList(accountedProperties);
        break;
    }

    return (
      <Row className="ProductMultiMatrix">
        <Col>
          <Container className="ProductMultiMatrix__container">
            {matrix}
          </Container>
        </Col>
      </Row>
    );
  }
}

ProductMultiMatrix.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  handleProductQuantity: PropTypes.func.isRequired,
  multi: modelOf(ProductMulti).isRequired,
  images: PropTypes.arrayOf(modelOf(ProductImageModel)),
};

export default inject('configStore', 'uiStore')(ProductMultiMatrix);
