import classNames from 'classnames';
import { head, size } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Analytics from '../../../analytics/Analytics';
import globalTranslations from '../../../i18n/globalTranslations';
import Product from '../../../models/Product';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import ProductClass from '../../../types/ProductClass';
import ProductEnquiryType from '../../../types/ProductEnquiryType';
import ProductLabelType from '../../../types/ProductLabelType';
import ProductPropertyType from '../../../types/ProductPropertyType';
import ProductTypeClass from '../../../types/ProductTypeClass';
import QuantityDiscountDisplayStyles from '../../../types/QuantityDiscountDisplayStyles';
import ImagePreload from '../../common/ImagePreload';
import ProductEnquiry from '../../product-enquiry/ProductEnquiry';
import ShoppingCenterProductMerchantInfo from '../../shopping-center/ShoppingCenterProductMerchantInfo';
import PriceSecondary from '../PriceSecondary';
import PriceWrapper from '../PriceWrapper';
import ProductAddToCart from '../ProductAddToCart';
import ProductCardProperties from '../ProductCardProperties';
import ProductCardPropertiesStatic from '../ProductCardPropertiesStatic';
import ProductCode from '../ProductCode';
import ProductDeposit from '../ProductDeposit';
import ProductDescription from '../ProductDescription';
import ProductFeatureImages from '../ProductFeatureImages';
import ProductImage from '../ProductImage';
import ProductImageSigns from '../ProductImageSigns';
import ProductLink from '../ProductLink';
import ProductPackageSize from '../ProductPackageSize';
import ProductReviewWidget from '../ProductReviewWidget';
import ProductSingularPrice from '../ProductSingularPrice';
import QuantityBasedPrice from '../QuantityBasedPrice';

const ProductCollectionPropertyTypeHeights = new Map()
  .set(ProductPropertyType.COLOR, 60)
  .set(ProductPropertyType.OTHER, 35)
  .set(ProductPropertyType.SIZE, 35);

@observer
export class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondaryImageActive: false,
      secondaryImageLoaded: false,
      selectedElementId: null,
      hoverProperties: false,
      quantity: 1,
    };
  }

  onEventStart = () => {
    this.enableSecondaryImage();
    // mobile uses static properties
    if (!this.props.uiStore.isMobile) {
      this.enableProperties();
    }
  };

  onEventEnd = () => {
    this.disableSecondaryImage();
    if (!this.props.uiStore.isMobile) {
      this.disableProperties();
    }
  };

  enableSecondaryImage = () => {
    if (!this.state.secondaryImageActive) {
      this.setState({ secondaryImageActive: true });
    }
  };

  disableSecondaryImage = () => {
    if (this.state.secondaryImageActive) {
      this.setState({ secondaryImageActive: false, selectedElementId: null });
    }
  };

  enableProperties = () => {
    if (!this.state.hoverProperties) {
      this.setState({ hoverProperties: true });
    }
  };

  disableProperties = () => {
    if (this.state.hoverProperties) {
      this.setState({ hoverProperties: false, selectedElementId: null });
    }
  };

  handleProductClick = () => {
    const { analytics, position, listName, listId, product } = this.props;
    analytics.productClick({
      listName,
      productList: [
        {
          product,
          position,
        },
      ],
      listId,
    });
  };

  onSecondaryImagePreload = () => {
    this.setState({
      secondaryImageLoaded: true,
    });
  };

  onFirstCollectionElementHovered = (hoveredElementId) => {
    this.setState({ selectedElementId: hoveredElementId });
  };

  productCollectionTotalHeight = () => {
    let height = 0;
    const {
      product: { collection },
    } = this.props;
    if (
      collection &&
      collection.column &&
      collection.column.id !== 0 &&
      collection.column.elements.length > 0 &&
      collection.column.elements[0].id !== 0
    ) {
      height += ProductCollectionPropertyTypeHeights.get(
        collection.column.type
      );
    }
    if (
      collection &&
      collection.row &&
      collection.row.id !== 0 &&
      collection.row.elements.length > 0 &&
      collection.row.elements[0].id !== 0
    ) {
      height += ProductCollectionPropertyTypeHeights.get(collection.row.type);
    }
    return height;
  };

  isColumnCollectionElementId = (product, elementId) => {
    return product.collection.column.elements.some((el) => el.id === elementId);
  };

  getMobileProperties = () => {
    const { product } = this.props;
    return <ProductCardPropertiesStatic product={product} />;
  };

  getAvailability = () => {
    const { accountStore, configStore, hideProperties, product } = this.props;
    const doHideProperties = hideProperties || accountStore.isViewOnly;
    const showProperties =
      !doHideProperties && configStore.productCard.showProperties;
    const availability = product.listingAvailability;

    if (
      !availability ||
      availability.length === 0 ||
      (product.class === ProductClass.COLLECTION && showProperties)
    ) {
      return null;
    }

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: availability,
        }}
      />
    );
  };

  getReserveInStore = () => {
    const { product } = this.props;

    if (!product.is_reservable_in_store) {
      return null;
    }

    return (
      <div className="ProductCard__reservation">
        <FormattedMessage {...globalTranslations.reservationTitle} />
      </div>
    );
  };

  getLabelsBelowTitle = () => {
    const { product } = this.props;
    const textLabels = product.labels.filter(
      (label) =>
        label.show_on_product_listings &&
        label.placement === 'product_properties' &&
        label.display_style !== ProductLabelType.IMAGE
    );

    const imageLabels = product.labels.filter(
      (label) =>
        label.show_on_product_listings &&
        label.placement === 'product_properties' &&
        label.display_style === ProductLabelType.IMAGE
    );

    if (textLabels.length === 0 && imageLabels.length === 0) {
      return null;
    }

    let imageLabelHtml = null;
    if (imageLabels.length > 0) {
      imageLabelHtml = (
        <div className="ProductCard__LabelImageContainer">
          {imageLabels.map((label, index) => (
            <img
              alt=""
              key={index}
              className="ProductCard__LabelImage"
              src={label.image}
              loading="lazy"
            />
          ))}
        </div>
      );
    }

    return (
      <>
        {imageLabelHtml && imageLabelHtml}
        <ul className="ProductCard__LabelList">
          {textLabels.map((labelObject, index) => {
            const { label } = labelObject;
            const label_text = label ? label.label_text : null;
            const label_value = label ? label.label_value : null;

            return (
              <li
                key={index}
                className={classNames('ProductCard__LabelListItem', {
                  'ProductCard__LabelListItem--withImage': labelObject.image,
                })}
              >
                {labelObject.image && (
                  <img
                    alt=""
                    className="ProductCard__LabelImage"
                    src={labelObject.image}
                    loading="lazy"
                  />
                )}
                {label_text && (
                  <strong className="ProductLabel__propertyName">
                    {label.label_text}
                  </strong>
                )}
                {label_value && (
                  <span className="ProductLabel__propertyValue">
                    : {label_value}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  getProductQuantity = (quantity) => {
    this.setState({ quantity });
  };

  getProductManufacturerId = () => {
    const { product } = this.props;
    const manufacturer = product.manufacturer;
    return product && manufacturer && manufacturer.id;
  };

  renderShoppingCenterProductMerchantInfo = () => {
    const { product } = this.props;
    const merchantInfo = product.merchantInfo;

    return <ShoppingCenterProductMerchantInfo merchantInfo={merchantInfo} />;
  };

  ifShowModel = () => {
    const { configStore, product } = this.props;

    if (product.class === ProductClass.MULTI) {
      return (
        configStore.productPage.showMultiProductModel &&
        configStore.product.showModel
      );
    }

    return configStore.product.showModel;
  };

  renderProductInformation = () => {
    const { configStore, product } = this.props;
    let hasContent = false;

    if (configStore.productCard.showProductCode) {
      hasContent = true;
    }
    if (configStore.productCard.showEanCode && product.ean) {
      hasContent = true;
    }
    if (configStore.productCard.showExtraId && product.extra_id) {
      hasContent = true;
    }
    if (
      configStore.productCard.showManufacturersCode &&
      product.manufacturer_product_id
    ) {
      hasContent = true;
    }

    if (!hasContent) {
      return null;
    }

    return (
      <div className="ProductCard__information">
        {configStore.productCard.showProductCode && (
          <span className="ProductCard__productId">
            <ProductCode code={product.productCode} />
          </span>
        )}
        {configStore.productCard.showEanCode && product.ean && (
          <FormattedMessage
            {...globalTranslations.eanLabel}
            values={{ manufacturerCode: product.manufacturer_product_id }}
          />
        )}
        {configStore.productCard.showExtraId && product.extra_id && (
          <FormattedMessage
            {...globalTranslations.extraIdLabel}
            values={{ extraId: product.extra_id }}
          />
        )}
        {configStore.productCard.showManufacturersCode &&
          product.manufacturer_product_id && (
            <FormattedMessage
              {...globalTranslations.manufacturerCodeLabel}
              values={{ manufacturerCode: product.manufacturer_product_id }}
            />
          )}
      </div>
    );
  };

  render() {
    const {
      accountStore,
      configStore,
      uiStore,
      disableLinks,
      hideAddToCartButton,
      hideDescription,
      hidePrice,
      hideProperties,
      hideQuantity,
      listId,
      listName,
      modelMargin,
      position,
      product,
      productCardClassName,
      productListView,
      productParams,
      requireCanonicalLink,
      showAsSold,
      target,
    } = this.props;

    const { secondaryImageActive, selectedElementId, secondaryImageLoaded } =
      this.state;

    const isGiftCard = product.product_type === ProductTypeClass.GIFT_VOUCHER;
    const doHideDescription =
      hideDescription || !configStore.productCard.showDescription;

    const doHideProperties = hideProperties || accountStore.isViewOnly;
    const doHideReviews = accountStore.isViewOnly || isGiftCard;

    const hideAddToCart =
      hideAddToCartButton ||
      product.has_required_services ||
      !configStore.productCard.showAddToCart;

    const productImage = selectedElementId
      ? product.images.find((i) => i.for_color_id === selectedElementId) ||
        head(product.images)
      : head(product.images);

    const secondaryImage =
      size(product.images) > 1 &&
      !(
        product.class === ProductClass.COLLECTION &&
        !!product.images.find((i) => i.for_color_id !== null)
      )
        ? product.images[1]
        : null;
    let secondaryImagePreload = '';
    let productLinkParams = {};
    // If first collection element is selected, link will contain this selection as parameter
    if (selectedElementId) {
      if (this.isColumnCollectionElementId(product, selectedElementId)) {
        productLinkParams = { columnId: selectedElementId };
      } else {
        productLinkParams = { rowId: selectedElementId };
      }
    }

    productLinkParams = { ...productLinkParams, ...productParams };

    /* This is used to load secondary image when user hovers over image. Secondary image is shown when it is loaded (see below) */
    if (secondaryImage && secondaryImageActive && !secondaryImageLoaded) {
      secondaryImagePreload = (
        <ImagePreload
          src={secondaryImage.sizes['medium']}
          onImageLoad={this.onSecondaryImagePreload}
        />
      );
    }

    const showProperties =
      !doHideProperties && configStore.productCard.showProperties;
    const propertiesHeight = showProperties
      ? this.productCollectionTotalHeight()
      : 0;

    const price = <PriceWrapper product={product} />;

    let doHidePrice =
      !price ||
      hidePrice ||
      (accountStore.isViewOnly && !accountStore.showPrices) ||
      product.isProposalType(
        product.id,
        configStore.product.enquireForProposal
      );

    const productTitle = product.multiproduct_title || product.name;

    const quantityAllowed =
      accountStore.isRetailer || configStore.productCard.showQuantitySelection;

    const withTax = accountStore.showPricesWithTax;

    const renderProductImage = () => {
      const { lazyLoading, productImageSettings } = this.props;
      return (
        <div className={classNames('ProductCard__images-wrapper')}>
          {secondaryImagePreload}
          <ProductImage
            product={product}
            size="medium"
            productImage={productImage}
            lazyLoading={lazyLoading}
            {...productImageSettings}
          />
          {secondaryImage && (
            <div
              className={classNames('ProductCard__secondary-image-wrapper', {
                'ProductCard__secondary-image-wrapper--image-loaded':
                  secondaryImageLoaded,
                'ProductCard__secondary-image-wrapper--image-touched':
                  secondaryImageLoaded && secondaryImageActive,
              })}
              onMouseOver={this.onEventStart}
              onTouchStart={this.onEventStart}
              onMouseLeave={this.onEventEnd}
              onTouchEnd={this.onEventEnd}
            >
              <ProductImage
                product={product}
                size="medium"
                productImage={secondaryImage}
                lazyLoading={lazyLoading}
                {...productImageSettings}
              />
            </div>
          )}
          <div className="ProductCard__data-over-image">
            <ProductFeatureImages
              productListView={productListView}
              product={product}
              position={'product_image'}
            />
            <ProductImageSigns product={product} />
          </div>
        </div>
      );
    };

    const renderProductTitleAndModel = () => {
      return (
        <>
          <h2>{productTitle}</h2>
          {this.ifShowModel() && product.model && (
            <div
              className={classNames('ProductCard__model', {
                'ProductCard__model-margin': !!modelMargin,
              })}
            >
              {product.model}
            </div>
          )}
        </>
      );
    };

    const ifWebStore = configStore.siteConfig.isWebStore;
    const quantityDiscountDisplayStyle =
      configStore.product.productQuantityDiscountDisplayStyle;
    let hasDiscount = false;
    if (product.price_info) {
      hasDiscount = product.getDiscountInfo(
        accountStore.showPricesWithTax,
        quantityDiscountDisplayStyle
      ).hasDiscount;
    }

    const quantityDiscounts = product.getQuantityDiscounts().discounts;

    return (
      <article
        className={classNames(
          'ProductCard',
          productCardClassName,
          this.getProductManufacturerId()
        )}
        draggable={false}
      >
        {!disableLinks ? (
          <ProductLink
            product={product}
            target={target}
            onClick={this.handleProductClick}
            params={productParams}
            requireCanonical={requireCanonicalLink}
          >
            {renderProductImage()}
          </ProductLink>
        ) : (
          renderProductImage()
        )}
        <div
          className={classNames('ProductCard__product-data-wrapper', {
            [`ProductCard__product-data-wrapper--margin-top-${propertiesHeight}`]:
              showProperties && !uiStore.isMobile,
          })}
        >
          <div className="ProductCard__manufacturer">
            {product.manufacturer && product.manufacturer.name}
            &nbsp;
          </div>
          {!disableLinks ? (
            <ProductLink
              product={product}
              params={productLinkParams}
              onClick={this.handleProductClick}
              target={target}
              requireCanonical={requireCanonicalLink}
            >
              {renderProductTitleAndModel()}
            </ProductLink>
          ) : (
            renderProductTitleAndModel()
          )}
          {!doHideDescription && configStore.product.shortDescriptionTop && (
            <ProductDescription
              className="ProductCardDescription"
              product={product}
              overflowHandler={this.handleProductClick}
              productListView={productListView}
            />
          )}
          {this.getLabelsBelowTitle()}
          {this.renderProductInformation()}
          {!uiStore.isMobile && product.class === ProductClass.COLLECTION && (
            <div className="ProductCard__properties">
              {showProperties && (
                <ProductCardProperties
                  product={product}
                  onFirstCollectionElementHovered={
                    this.onFirstCollectionElementHovered
                  }
                  selectedElementId={selectedElementId}
                  filterNotInStockElements={configStore.inStockProductsOnly}
                />
              )}
            </div>
          )}
          {!doHideReviews && !configStore.siteConfig.isShoppingCenter && (
            <ProductReviewWidget product={product} compact />
          )}
          <div
            className={classNames('ProductCard__price-and-cart', {
              'ProductCard__price-and-cart-with-quantity': quantityAllowed,
            })}
          >
            <div className="ProductCard__price-and-availability">
              {!doHidePrice &&
                (quantityDiscountDisplayStyle ===
                  QuantityDiscountDisplayStyles.NORMAL_AND_LOWEST ||
                  !quantityDiscounts) &&
                price}
              {!doHidePrice && quantityDiscounts && (
                <QuantityBasedPrice
                  className="ProductPrice"
                  price={price}
                  quantityDiscounts={quantityDiscounts}
                  stockUnit={product.stock_unit}
                  withTax={accountStore.showPricesWithTax}
                  withDiscount={hasDiscount}
                />
              )}
              {!doHidePrice && product && (
                <ProductDeposit
                  product={product}
                  showPrice={!configStore.productPage.depositInPrice}
                />
              )}
              {!doHidePrice &&
                (configStore.product.showSecondaryTaxPrice ||
                  accountStore.isRetailer) && (
                  <PriceSecondary product={product} isProductCard />
                )}
              <ProductSingularPrice
                product={product}
                hideSingular={!configStore.productCard.showSingularPrice}
              />
              {ifWebStore &&
                !isGiftCard &&
                configStore.visibility.availability && (
                  <div className="ProductCard__availability">
                    {this.getAvailability()}
                  </div>
                )}
              {configStore.reserveInStore.enabled &&
                !isGiftCard &&
                this.getReserveInStore()}
            </div>
            {quantityAllowed && product.sellInPackage && (
              <ProductPackageSize product={product} />
            )}
            {!accountStore.isViewOnly &&
              !hideAddToCart &&
              !hideAddToCartButton &&
              product.class === ProductClass.SINGLE &&
              ifWebStore && (
                <ProductAddToCart
                  product={product}
                  addToCartProduct={product}
                  activeProductId={product.id}
                  hideQuantity={hideQuantity}
                  quantity={this.state.quantity}
                  productListView
                  simple
                  onUpdateProductQuantity={this.getProductQuantity}
                  position={position}
                  listName={listName}
                  listId={listId}
                  withServices={false}
                />
              )}
          </div>
          {showAsSold && doHidePrice && (
            <span className="ProductCard__sold-out">
              <FormattedMessage id="product.sold" defaultMessage="Sold" />
            </span>
          )}
          {doHidePrice &&
            product.isProposalType(
              product.id,
              configStore.product.enquireForProposal
            ) && (
              <ProductEnquiry
                id={product.id}
                price={product.getPrice(withTax)}
                type={ProductEnquiryType.PROPOSAL_REQUEST}
              />
            )}
          {!doHideDescription && configStore.product.shortDescriptionBottom && (
            <ProductDescription
              className="ProductCardDescription"
              product={product}
              overflowHandler={this.handleProductClick}
            />
          )}
          {showProperties && uiStore.isMobile && (
            <div className="ProductCard__properties--static">
              {this.getMobileProperties()}
            </div>
          )}
          {product.suitability_description && (
            <div className="ProductCard__suitability-description">
              <ProductDescription
                className="ProductCardDescription"
                product={product}
                isSuitability={true}
              />
            </div>
          )}
          {this.renderShoppingCenterProductMerchantInfo()}
        </div>
      </article>
    );
  }
}

ProductCard.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  product: modelOf(Product).isRequired,
  listId: PropTypes.string,
  listName: PropTypes.string,
  target: PropTypes.string,
  productCardClassName: PropTypes.string,
  position: PropTypes.number,
  disableLinks: PropTypes.bool,
  hideAddToCartButton: PropTypes.bool,
  hideDescription: PropTypes.bool,
  hidePrice: PropTypes.bool,
  hideProperties: PropTypes.bool,
  hideQuantity: PropTypes.bool,
  lazyLoading: PropTypes.bool,
  modelMargin: PropTypes.bool,
  productListView: PropTypes.bool,
  // Card is used in product list
  requireCanonicalLink: PropTypes.bool,
  showAsSold: PropTypes.bool,
  productImageSettings: PropTypes.object,
};

ProductCard.defaultProps = {
  hidePrice: false,
  hideQuantity: false,
  requireCanonicalLink: false,
  lazyLoading: true,
};

export default inject(
  'analytics',
  'configStore',
  'uiStore',
  'accountStore'
)(ProductCard);
