import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Discount from '../Discount';

class DiscountSign extends Component {
  render() {
    const { percentage, withLabel, withUpTo, inverted, className } = this.props;
    if (!percentage) {
      return null;
    }

    return (
      <div
        className={classNames('DiscountSign', className, {
          'DiscountSign--inverted': inverted
        })}
      >
        <Discount
          percentage={percentage}
          withLabel={withLabel}
          withUpTo={withUpTo}
        />
      </div>
    );
  }
}

DiscountSign.propTypes = {
  percentage: PropTypes.number,
  withLabel: PropTypes.bool,
  withUpTo: PropTypes.bool,
  inverted: PropTypes.bool,
  className: PropTypes.string
};

export default DiscountSign;
