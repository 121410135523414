import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { inject, observer } from 'mobx-react';
import { Row, Table } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ProductProperty from '../../../models/product/ProductProperty';
import ProductAvailabilityText from '../ProductAvailabilityText';
import ProductPrice from '../ProductPrice';
import ProductMulti from '../../../models/ProductMulti';
import globalTranslations from '../../../i18n/globalTranslations';
import ConfigStore from '../../../store/ConfigStore';
import ProductImage from '../ProductImage';
import { initialImageOrigin } from '../../common/ImageLightbox/ImageLightboxProductImage';
import { values } from 'mobx';

const ImageLightbox = loadable(() =>
  import(
    /* webpackChunkName: "common" */ '../../common/ImageLightbox/ImageLightboxModal'
  )
);

const ProductMultiPropertyList = ({
  configStore,
  multi,
  property,
  onRenderQuantityInput,
}) => {
  if (!property) {
    return null;
  }

  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [lightboxIsOpen, toggleLightbox] = useState(false);
  const [activeProductId, setActiveProduct] = useState(null);

  useEffect(() => {
    if (activeProductId) openLightbox();
  }, [activeProductId]);

  let lightboxInitialState = {};

  const imageLightboxOnClickHandler = useCallback(() => {
    toggleLightbox(false);
    setActiveProduct(null);
  }, []);

  const getImageLightbox = () => {
    const childProduct = multi.findChild(activeProductId);

    if (childProduct?.images.length) {
      childProduct.images.forEach((_, index) => {
        lightboxInitialState = {
          ...lightboxInitialState,
          [index]: {
            focus: false,
            scale: 1,
            origin: initialImageOrigin,
          },
        };
      });

      lightboxInitialState.selectedImage = mainImageIndex;

      return (
        <ImageLightbox
          images={childProduct.images}
          mainImageIndex={mainImageIndex}
          onClick={imageLightboxOnClickHandler}
          lightboxIsOpen={lightboxIsOpen}
          initialState={lightboxInitialState}
          product={childProduct}
        />
      );
    }

    return null;
  };

  const openLightbox = () => {
    const childProduct = multi.findChild(activeProductId);
    if (childProduct?.images.length) {
      const childProductImageIndex = childProduct.images.indexOf(
        childProduct.images[0]
      );

      setMainImageIndex(childProductImageIndex);
      toggleLightbox(true);
    }
  };

  const setActiveChildProduct = useCallback(
    (childProductId) => setActiveProduct(childProductId),
    []
  );

  const shouldRenderProductCode = () => {
    const showProductCode = configStore.productPage.showProductCode;
    const showAdditionalProductCode =
      configStore.productPage.showAdditionalProductCode;

    if (!showProductCode || !showAdditionalProductCode) {
      return false;
    }

    return true;
  };

  const createPropertyElement = (propertyID, elementID) => {
    return { [propertyID]: elementID };
  };

  const renderPropertyRows = () => {
    return property.elements.map((element) => {
      const propertyElement = createPropertyElement(property.id, element.id);
      const childProduct = multi.findChildWithProperties(propertyElement);

      return (
        <tr
          key={element.id}
          className="ProductMultiPropertyList__product-cell-row"
        >
          {shouldRenderProductCode() && (
            <td className="ProductMultiPropertyList__cell">
              {childProduct.productCode}
            </td>
          )}
          <td
            className="ProductMultiPropertyList__cell"
            onClick={() => setActiveChildProduct(childProduct.id)}
          >
            <ProductImage
              product={childProduct}
              productImage={childProduct.images[0]}
              size={'small'}
              forceAspectRatio={false}
              lazyLoading={false}
            />
          </td>
          <td className="ProductMultiPropertyList__cell">
            {childProduct.name}
          </td>
          <td className="ProductMultiPropertyList__cell">
            <ProductAvailabilityText
              availabilityHtml={childProduct.availability_html}
            />
          </td>
          <td className="ProductMultiPropertyList__cell">{element.name}</td>
          <td className="ProductMultiPropertyList__cell">
            <ProductPrice
              product={childProduct}
              priceInfo={childProduct.price_info}
            />
          </td>
          <td className="ProductMultiPropertyList__cell">
            {onRenderQuantityInput(childProduct)}
          </td>
        </tr>
      );
    });
  };

  const renderHeaderRow = () => {
    return (
      <tr className="ProductMultiPropertyList__product-header-row">
        {shouldRenderProductCode() && (
          <th className="ProductMultiPropertyList__header">
            <FormattedMessage {...globalTranslations.productCodeTitle} />
          </th>
        )}
        <th className="ProductMultiPropertyList__header">
          <FormattedMessage {...globalTranslations.imageTitle} />
        </th>
        <th className="ProductMultiPropertyList__header">
          <FormattedMessage {...globalTranslations.productNameTitle} />
        </th>
        <th className="ProductMultiPropertyList__header">
          <FormattedMessage {...globalTranslations.availabilityTitle} />
        </th>
        <th className="ProductMultiPropertyList__header">{property.name}</th>
        <th className="ProductMultiPropertyList__header">
          <FormattedMessage {...globalTranslations.priceTitle} />
        </th>
        <th className="ProductMultiPropertyList__header">
          <FormattedMessage {...globalTranslations.quantityTitle} />
        </th>
      </tr>
    );
  };

  const shouldRenderLightBox =
    lightboxIsOpen && multi.findChild(activeProductId)?.images.length > 0
      ? true
      : false;

  return (
    <Row className="ProductMultiPropertyList">
      <Table>
        <thead>{renderHeaderRow()}</thead>
        <tbody>{renderPropertyRows()}</tbody>
      </Table>
      {shouldRenderLightBox && getImageLightbox()}
    </Row>
  );
};

ProductMultiPropertyList.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  multi: modelOf(ProductMulti).isRequired,
  property: modelOf(ProductProperty).isRequired,
  onRenderQuantityInput: PropTypes.func.isRequired,
};

export default injectIntl(
  inject('configStore')(observer(ProductMultiPropertyList))
);
