import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import QuantityDiscountDisplayStyles from '../../../types/QuantityDiscountDisplayStyles';
import Price from '../Price';
import globalTranslations from '../../../i18n/globalTranslations';
import AccountStore from '../../../store/AccountStore';
import ProductPriceRange from '../ProductPriceRange';
import QuantityDiscounts from '../../../models/QuantityDiscounts';

const QuantityBasedPrice = ({
  accountStore,
  configStore,
  className,
  price,
  quantityDiscounts,
  stockUnit,
  withDiscount,
  withTax,
}) => {
  const renderLowestPrice = () => {
    return (
      <div
        className={classNames(
          'QuantityBasedPrice QuantityBasedPrice__lowest',
          className
        )}
      >
        <span className="QuantityBasedPrice__lowest-price-from">
          <FormattedMessage {...globalTranslations.priceFrom} />
        </span>
        {getQuantityDiscount().price}
      </div>
    );
  };

  const renderRangePrice = () => {
    const priceRange = getMinMaxPrice();
    const { hideTaxIncludedInfo, retailer } = showPrices();
    const showTaxIncludedInfo = withTax && (hideTaxIncludedInfo || retailer);

    return (
      <span
        className={classNames(
          'QuantityBasedPrice QuantityBasedPrice__range-price',
          className
        )}
      >
        <ProductPriceRange
          range={priceRange}
          withTax={withTax}
          showTaxIncludedInfo={showTaxIncludedInfo}
        />
      </span>
    );
  };

  const getMinMaxPrice = () => {
    return {
      min: quantityDiscounts.lowest,
      max: quantityDiscounts.highest,
    };
  };

  const renderNormalAndLowestPrice = () => {
    const quantityDiscount = getQuantityDiscount();

    return (
      <div
        className={classNames(
          'QuantityBasedPrice QuantityBasedPrice__normal',
          className
        )}
      >
        <span className="QuantityBasedPrice__singular-price">
          <FormattedMessage
            id="quantityBasedPrice.price"
            defaultMessage="or {quantity} {stockUnit} for price {price}"
            values={{
              quantity: quantityDiscount.quantity,
              stockUnit: stockUnit,
              price: quantityDiscount.price,
            }}
          />
        </span>
      </div>
    );
  };

  const showPrices = () => {
    const hideTaxIncludedInfo = configStore.product.showSecondaryTaxPrice;
    const retailer = accountStore.isRetailer;
    return { hideTaxIncludedInfo, retailer };
  };

  const getQuantityDiscount = () => {
    const { hideTaxIncludedInfo, retailer } = showPrices();
    const showTaxIncludedInfo = withTax && (hideTaxIncludedInfo || retailer);

    return {
      price: (
        <Price
          price={quantityDiscounts.lowest.price.getPrice(withTax)}
          discount={withDiscount}
          showTaxIncludedInfo={showTaxIncludedInfo}
          showTaxExcludedInfo={!withTax}
          suffix={<span> / {stockUnit}</span>}
        />
      ),
      quantity: quantityDiscounts.lowest.quantity,
    };
  };

  switch (configStore.product.productQuantityDiscountDisplayStyle) {
    case QuantityDiscountDisplayStyles.LOWEST:
      return renderLowestPrice();
    case QuantityDiscountDisplayStyles.RANGE:
      return renderRangePrice();
    case QuantityDiscountDisplayStyles.NORMAL_AND_LOWEST:
      return renderNormalAndLowestPrice();
    default:
      return price;
  }
};

QuantityBasedPrice.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  withTax: PropTypes.bool.isRequired,
  className: PropTypes.string,
  price: PropTypes.node,
  quantityDiscounts: modelOf(QuantityDiscounts),
  stockUnit: PropTypes.string,
  withDiscount: PropTypes.bool,
};

export default inject(
  'accountStore',
  'configStore'
)(observer(QuantityBasedPrice));
