import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';

import DiscountSign from '../DiscountSign';
import { modelOf } from '../../../prop-types';
import ProductPriceInfo from '../../../models/ProductPriceInfo';
import globalTranslations from '../../../i18n/globalTranslations';
import Price from '../Price';
import ProductSingularPrice from '../ProductSingularPrice';
import ConfigStore from '../../../store/ConfigStore';
import QuantityBasedPrice from '../QuantityBasedPrice';
import QuantityDiscountDisplayStyles from '../../../types/QuantityDiscountDisplayStyles';
import QuantityDiscounts from '../../../models/QuantityDiscounts';

@observer
export class ProductPagePrice extends Component {
  renderDiscountDescription = () => {
    const { product } = this.props;
    const discountPercentage = Math.round(
      product.price_info.discount_percentage
    );
    if (discountPercentage >= 40) {
      return (
        <FormattedMessage
          id="product.topDiscount"
          defaultMessage="Top discount"
        />
      );
    } else if (discountPercentage >= 30) {
      return (
        <FormattedMessage
          id="product.superOffer"
          defaultMessage="Super offer"
        />
      );
    } else {
      return (
        <FormattedMessage id="product.nowOnly" defaultMessage="Now only" />
      );
    }
  };

  render() {
    const {
      configStore,
      className,
      product,
      price,
      secondaryPrice,
      quantityDiscounts,
      withTax,
      withDiscountLabel,
      withDiscountUpTo,
      withDiscountBalloon,
      withDiscountSavings,
      discountInverted,
      isMulti,
      isSecondaryPrice,
    } = this.props;

    const quantityDiscountDisplayStyle =
      configStore.product.productQuantityDiscountDisplayStyle;

    const { savings, discountAmount, hasDiscount, hasPriceRange } =
      product.getDiscountInfo(withTax, quantityDiscountDisplayStyle);

    const showDiscountBalloon = withDiscountBalloon && !hasPriceRange;
    const showDiscountSavings = withDiscountSavings && !hasPriceRange;

    return (
      <div className="ProductPagePrice">
        {hasDiscount && showDiscountBalloon && (
          <div className="ProductPagePrice__balloon">
            <FormattedMessage {...globalTranslations.productOffer} />
          </div>
        )}
        {hasDiscount && showDiscountSavings && (
          <div className="ProductPagePrice__savings">
            {this.renderDiscountDescription()}
            <span className="ProductPagePrice__savings-amount">
              <FormattedMessage
                {...globalTranslations.youSaveSentence}
                values={{
                  savings: <Price price={savings} />,
                }}
              />
            </span>
          </div>
        )}
        <div className="ProductPagePrice__price-container">
          {(isMulti ||
            !quantityDiscounts ||
            quantityDiscountDisplayStyle ===
              QuantityDiscountDisplayStyles.NORMAL_AND_LOWEST) &&
            price}
          {!isMulti &&
            quantityDiscounts &&
            quantityDiscountDisplayStyle !==
              QuantityDiscountDisplayStyles.NORMAL_AND_LOWEST && (
              <QuantityBasedPrice
                price={price}
                className={'ProductPrice'}
                quantityDiscounts={quantityDiscounts}
                stockUnit={product.stock_unit}
                withTax={withTax}
                withDiscount={!isSecondaryPrice && hasDiscount}
              />
            )}
          {hasDiscount && !isSecondaryPrice && (
            <DiscountSign
              percentage={discountAmount}
              withLabel={withDiscountLabel}
              withUpTo={withDiscountUpTo || hasPriceRange}
              inverted={discountInverted}
              className={className}
            />
          )}
        </div>
        <ProductSingularPrice product={product} withTax={withTax} />
        {product.price_info &&
          product.price_info.valid_until_html &&
          !isSecondaryPrice && (
            <div
              className="ProductPagePrice__valid-until"
              dangerouslySetInnerHTML={{
                __html: product.price_info.valid_until_html,
              }}
            />
          )}
        {secondaryPrice}
        {!isMulti &&
          quantityDiscounts &&
          quantityDiscountDisplayStyle ===
            QuantityDiscountDisplayStyles.NORMAL_AND_LOWEST && (
            <QuantityBasedPrice
              className={'ProductPagePrice__quantity-based-price'}
              quantityDiscounts={quantityDiscounts}
              stockUnit={product.stock_unit}
              withTax={withTax}
              withDiscount={hasDiscount}
            />
          )}
      </div>
    );
  }
}

ProductPagePrice.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  product: PropTypes.shape({
    class: PropTypes.string,
    price_info: modelOf(ProductPriceInfo),
    package_size: PropTypes.number,
    stock_unit: PropTypes.string,
    singular_price_unit: PropTypes.string,
    singular_price_divider: PropTypes.number,
  }).isRequired,
  withTax: PropTypes.bool.isRequired,
  price: PropTypes.node.isRequired,
  quantityDiscounts: modelOf(QuantityDiscounts),
  className: PropTypes.string,
  discountInverted: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSecondaryPrice: PropTypes.bool,
  withDiscountBalloon: PropTypes.bool,
  withDiscountLabel: PropTypes.bool,
  withDiscountSavings: PropTypes.bool,
  withDiscountUpTo: PropTypes.bool,
  secondaryPrice: PropTypes.node,
};

export default inject((stores, props) => ({
  configStore: stores.configStore,
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax,
}))(ProductPagePrice);
