import { types } from 'mobx-state-tree';
import SectionStatusType, {
  SectionStatusTypeType,
} from '../types/SectionStatusType';
import Filter from './Filter';
import Product from './Product';

const ProductSearchPaginator = types
  .model('ProductSearchPaginator', {
    available_filters: types.maybeNull(types.array(Filter)),
    current_page: types.number,
    data: types.optional(types.array(types.reference(Product)), []),
    last_page: types.number,
    section: types.maybeNull(
      types.model({
        status: SectionStatusTypeType,
      })
    ),
    total: types.number,
  })
  .views((self) => ({
    get isTestMode() {
      return self.section?.status === SectionStatusType.TEST_MODE ?? false;
    },
  }));

export default ProductSearchPaginator;
