import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FlagIcon from '../FlagIcon';

const LOCALE_TO_COUNTRY_CODE_MAPPING = {
  en: 'gb',
  sv: 'se'
};

class LocaleFlagIcon extends Component {
  static countryCodeFromLocale(locale) {
    // If the country has no mapping set above, it is assumed that the
    // equivalent country code is the same as the locale.
    // (i.e. fi locale and fi country are a pair)
    return LOCALE_TO_COUNTRY_CODE_MAPPING[locale] || locale;
  }

  render() {
    const { locale } = this.props;

    return (
      <FlagIcon countryCode={LocaleFlagIcon.countryCodeFromLocale(locale)} />
    );
  }
}

LocaleFlagIcon.propTypes = {
  locale: PropTypes.string.isRequired
};

export default LocaleFlagIcon;
