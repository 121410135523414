import React, { Component } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import enLocale from 'react-intl/locale-data/en';
import fiLocale from 'react-intl/locale-data/fi';
import svLocale from 'react-intl/locale-data/sv';
import ruLocale from 'react-intl/locale-data/ru';
import noLocale from 'react-intl/locale-data/no';
import enMessages from '../messages/en.json';
import fiMessages from '../messages/fi.json';
import svMessages from '../messages/sv.json';
import ruMessages from '../messages/ru.json';
import noMessages from '../messages/no.json';
import { modelOf } from '../../prop-types';
import LanguageStore from '../../store/LanguageStore';
import TranslationStore from '../../store/TranslationStore';

addLocaleData([
  ...enLocale,
  ...fiLocale,
  ...svLocale,
  ...ruLocale,
  ...noLocale
]);
const validLanguages = ['en', 'fi', 'sv', 'ru', 'no'];

@observer
export class IntlWrapper extends Component {
  constructor(props) {
    super(props);

    this.translations = {
      en: enMessages,
      fi: fiMessages,
      sv: svMessages,
      ru: ruMessages,
      no: noMessages
    };
  }

  render() {
    const {
      languageStore,
      translationStore,
      overrideLocale,
      loadableMessages
    } = this.props;

    const locale =
      !!overrideLocale && validLanguages.includes(overrideLocale)
        ? overrideLocale
        : languageStore.activeLocale;
    const appMessages = this.translations[locale] || this.translations.en;
    let extraMessages;
    if (loadableMessages) {
      extraMessages = loadableMessages[locale] || loadableMessages.en;
    }

    // Override messages with those provided by the backend
    // Extra messages include translations that are not needed by default and should be lazy loaded at some point.
    const messages = {
      ...appMessages,
      ...translationStore.messages,
      ...extraMessages
    };

    // The locale and translation are both loaded only at the start of the page load. Lazy loading will be added later.
    // Locale changes from the UI will reload the page to make sure the new locale is loaded fully.
    return (
      <IntlProvider locale={locale} messages={messages}>
        {this.props.children}
      </IntlProvider>
    );
  }
}

IntlWrapper.propTypes = {
  languageStore: modelOf(LanguageStore).isRequired,
  translationStore: modelOf(TranslationStore).isRequired,
  loadableMessages: PropTypes.object,
  overrideLocale: PropTypes.string
};

export default IntlWrapper;
