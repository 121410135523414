import React, { Component } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { matchPath, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';

import qs from 'query-string';
import { modelOf } from '../../../prop-types';
import CartStore from '../../../store/CartStore';
import Icon from '../../common/Icon';
import Price from '../../product/Price';
import globalTranslations from '../../../i18n/globalTranslations';
import UIStore from '../../../store/UIStore';
import Paths from '../../../types/Paths';
import RequestState from '../../../types/RequestState';
import CartNewProductType from '../../../types/CartNewProductType';
import ProductImage from '../../product/ProductImage';
import ProductImageModel from '../../../models/ProductImage';
import Analytics from '../../../analytics/Analytics';
import RouteService from '../../../services/RouteService';
import SectionStore from '../../../store/SectionStore';
import AlsoPurchasedProductsSlider from '../../slider/AlsoPurchasedProductsSlider';
import ProductStore from '../../../store/ProductStore';
import ConfigStore from '../../../store/ConfigStore';
import ProductPdf from '../../product-pdf/ProductPdf';
import ProductPdfType from '../../../types/ProductPdfType';
import CampaignCodeStore from '../../../store/CampaignCodeStore';
import CartProductListing from '../CartProductListing';
import CartProductRow from '../CartProductRow';

@observer
export class CartModal extends Component {
  constructor(props) {
    super(props);

    this.rightColumn = React.createRef();

    this.state = {
      rightColumnHeight: null,
    };

    this.timer = null;
  }

  componentDidMount() {
    const { cartStore, history } = this.props;
    const { cartModal } = cartStore;

    const populated = this.handleCartPopulate();
    if (!populated) {
      this.maybeLoadCart();
    }

    // Close modal if user navigates to new product with AlsoPurchasedSlider
    history.listen((location) => {
      if (cartModal.isOpen) {
        cartModal.toggle();
      }
    });
  }

  componentWillUnmount() {
    this.resetTimer();
  }

  resetTimer = () => {
    this.timer && clearTimeout(this.timer);
  };

  updateRightColumnHeight = () => {
    const rightColumnHeight = this.rightColumn
      ? this.rightColumn.clientHeight
      : 300;

    this.setState({
      rightColumnHeight: rightColumnHeight,
    });
  };

  maybeLoadCart = () => {
    const {
      cartStore,
      analytics,
      location,
      routeService,
      sectionStore,
      configStore,
    } = this.props;
    if (
      cartStore.state === RequestState.NONE &&
      !!!window.isSSR &&
      !configStore.siteConfig.isShoppingCenter
    ) {
      cartStore.loadCart().then(() => {
        const thankYouPath = matchPath(location.pathname, {
          path: routeService.getPath(
            Paths.ThankYouPage,
            sectionStore.activeSection
          ),
          strict: false,
          exact: true,
        });

        if (!thankYouPath) {
          analytics.cartStatus({
            cart: cartStore.cart,
          });
        }
      });
    }
  };

  sendAnalyticsViewCartEvent = () => {
    const { cartStore, analytics, withTax } = this.props;

    const cartProducts = cartStore.cart.products;
    const value = cartStore.cart.getCartTotalValue(withTax);
    analytics.viewCart(value, cartProducts);
  };

  handleOpen = () => {
    if (typeof document === 'undefined') {
      return null;
    }
    const { cartStore, configStore } = this.props;
    document.body.classList.add('modal-open');

    this.updateRightColumnHeight();
    cartStore.cartModal.isOpen &&
      configStore.analytics.ga4.enabled &&
      this.sendAnalyticsViewCartEvent();
  };

  handleCartPopulate = () => {
    const params = qs.parse(this.props.location.search, {
      arrayFormat: 'brackets',
    });
    const { action } = params;
    const { cartStore, history, location } = this.props;

    if (!action || action !== 'populate') {
      return false;
    }

    const productArr = this.getProductsIdsFromUrl(params);

    if (productArr.length > 0) {
      cartStore.populate(productArr);
      history.replace({
        ...location,
        search: qs.stringify({}),
      });
      return true;
    }

    return false;
  };

  getProductsIdsFromUrl = (params) => {
    const regex = /product\[(.+)\]/;
    return Object.keys(params)
      .map((param) => {
        const match = regex.exec(param);

        if (match) {
          return {
            id: match[1],
            qty: Number(params[param]),
          };
        }

        return null;
      })
      .filter((element) => element);
  };

  changeBasket = (event) => {
    const { cartStore } = this.props;
    cartStore.setBasket(event.target.value);
  };

  getNewProductTitle = () => {
    const { new_product_info } = this.props.cartStore.cart;
    let message;
    if (!new_product_info) {
      return null;
    }

    switch (new_product_info.type) {
      case CartNewProductType.BUNDLE:
        message = (
          <FormattedMessage
            {...globalTranslations.productBundleAddedToCartSentence}
          />
        );
        break;
      case CartNewProductType.NORMAL:
      case CartNewProductType.ARRAY:
      default:
        message = (
          <FormattedMessage
            {...globalTranslations.productAddedToCartSentence}
            values={{ quantity: new_product_info.quantity }}
          />
        );
        break;
    }
    return message;
  };

  createProductImage = (url) => {
    return url
      ? ProductImageModel.create({
          id: 0,
          product_id: '',
          sizes: {
            small: url,
          },
        })
      : null;
  };

  getAlsoPurchased = () => {
    const { alsoPurchasedProductId } = this.props.cartStore;

    if (!alsoPurchasedProductId) {
      return null;
    }

    const actualId = alsoPurchasedProductId.split('{')[0];
    const product = this.props.productStore.products.get(actualId);

    if (!product) {
      return null;
    }

    return (
      <Row>
        <Col className="CartModal__also-purchased mt-2">
          <AlsoPurchasedProductsSlider
            variant="cartModal"
            activeProductId={product.id}
            product={product}
          />
        </Col>
      </Row>
    );
  };

  getNewProductContent = () => {
    const { withTax } = this.props;
    const { new_product_info } = this.props.cartStore.cart;

    if (
      !new_product_info ||
      new_product_info.type === CartNewProductType.ARRAY
    ) {
      return null;
    }
    const showUnit = !!new_product_info.stock_unit;

    return (
      <Row>
        <Col className="CartModal__left-column-left" xs={4}>
          <div className="CartModal__column-image">
            <ProductImage
              product={new_product_info}
              productImage={this.createProductImage(new_product_info.image)}
              size="small"
              forceAspectRatio={false}
              lazyLoading={false}
            />
          </div>
        </Col>
        <Col xs={8}>
          <div className="CartModal__column-info">
            <div className="CartModal__column-manufacturer">
              {new_product_info.manufacturer}
            </div>
            <div className="CartModal__column-name">
              <span>{new_product_info.name}</span>
              <span>{new_product_info.productCode}</span>
            </div>
            {new_product_info.variations.length > 0 && (
              <div className="CartModal__column-variations">
                {new_product_info.variations.map((variation) => (
                  <span key={variation} className="CartModal__column-variation">
                    {variation}
                  </span>
                ))}
              </div>
            )}
            {new_product_info.price && (
              <div className="CartModal__column-price">
                <Price price={new_product_info.price.getPrice(withTax)} />
                {showUnit && (
                  <span className="CartModal__stock-unit">
                    {' '}
                    / {new_product_info.stock_unit}
                  </span>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  getOptions = (options) => {
    const { withTax } = this.props;

    return options.map((option, index) => {
      let info = '';
      if (option.info) {
        info = `(${option.info})`;
      }

      return (
        <Row key={index}>
          <Col className="CartModal__price" xs={12}>
            <div className="CartModal__price-options">{option.title}</div>
            <div className="CartModal__price-options-price">
              <Price price={option.price.getPrice(withTax)} />
            </div>
          </Col>
          <Col className="CartModal__price">
            <div
              dangerouslySetInnerHTML={{ __html: info }}
              className="CartModal__price-option-info"
            />
          </Col>
        </Row>
      );
    });
  };

  getNewProductPriceInfo = () => {
    const { cartStore, withTax } = this.props;
    const { cart } = cartStore;
    return (
      <>
        <Row>
          <Col className="CartModal__price">
            <div className="CartModal__price-products">
              <FormattedMessage
                id="cart.productsTotalLabel"
                defaultMessage="Products total:"
              />
            </div>
            <div className="CartModal__price-products-price">
              <Price price={cart.total.getPrice(withTax)} />
            </div>
          </Col>
        </Row>
        {this.getOptions(cart.options)}
        <Row>
          <Col className="CartModal__price">
            <div className="CartModal__price-products-extra">
              <FormattedMessage
                id="cart.productsTotalWithExtraLabel"
                defaultMessage="Total amount"
              />{' '}
              <FormattedMessage {...globalTranslations.sumWithVat} />
            </div>
            <div className="CartModal__price-products-extra-price">
              <Price price={cart.total_with_extra_costs.getPrice(withTax)} />
            </div>
          </Col>
        </Row>
      </>
    );
  };

  onClickHandler = () => {
    const { cartStore } = this.props;

    cartStore.toggleCartButton();

    this.timer = setTimeout(() => {
      cartStore.closeModal();
      cartStore.toggleCartButton();
    }, 1700);

    this.sendAnalyticsCheckoutEvent();
  };

  sendAnalyticsCheckoutEvent = () => {
    const { analytics, campaignCodeStore, cartStore, configStore, withTax } =
      this.props;

    if (!configStore.gtm.enabled) {
      return;
    }

    let coupon;
    const activeCoupon = campaignCodeStore.activeCoupon;
    if (activeCoupon && !activeCoupon.hasProductLimitations()) {
      coupon = activeCoupon.code;
    }

    const cartProducts = cartStore.cart.products;
    const actionField = { step: 1, option: 'Move To Checkout' };
    const value = cartStore.cart.getCartTotalValue(withTax);
    analytics.checkout(cartProducts, actionField, value, coupon);
  };

  pdfCatalogEnabled = () => {
    const { accountStore, cartStore, configStore } = this.props;
    const { showPdfButton } = configStore.cart.modal;

    if (showPdfButton === '0' || cartStore.cart.products.length === 0) {
      return false;
    }
    if (
      showPdfButton === '1' ||
      (showPdfButton === '2' && accountStore.loggedIn)
    ) {
      return true;
    }
    return showPdfButton === '3' && accountStore.isRetailer;
  };

  getCheckoutButtonContent = () => {
    const { cartStore } = this.props;

    return cartStore.cartButtonDisabled ? (
      <FormattedMessage {...globalTranslations.loading} />
    ) : (
      <FormattedMessage {...globalTranslations.proceedToCheckoutSentence} />
    );
  };

  getCartContent = () => {
    const { cartStore, uiStore, configStore, intl } = this.props;
    const { cart } = cartStore;
    const { checkout } = configStore;
    const { showImages } = configStore.cart.modal;

    const showLeftColumn = !cartStore.cartModal.openedManually;
    const showProductListingColumn =
      cartStore.cartModal.openedManually &&
      showImages &&
      cart.products.length > 0;
    const leftColumnActive = showLeftColumn || showProductListingColumn;
    const showHigherButton = showLeftColumn && uiStore.isMobile;
    const openedManually = cartStore.cartModal.openedManually;

    const continueShoppingButton = (
      <div className="CartModal__continue">
        <Button
          color="secondary"
          onClick={cartStore.cartModal.toggle}
          size="lg"
          block
        >
          <Icon className="CartModal__continue-icon" name="angle-double-left" />
          <FormattedMessage
            id="cart.continueShopping"
            defaultMessage="Continue shopping"
          />
        </Button>
      </div>
    );

    const checkoutButton = (
      <div className="CartModal__checkout">
        <Button
          block
          color="primary"
          tag="a"
          href={checkout.checkoutPath}
          rel="noopener"
          size="lg"
          onClick={this.onClickHandler}
          aria-label={intl.formatMessage(
            globalTranslations.proceedToCheckoutSentence
          )}
          disabled={cartStore.cartButtonDisabled}
        >
          <Icon className="CartModal__checkout-icon" name="shopping-cart" />
          {this.getCheckoutButtonContent()}
        </Button>
      </div>
    );

    // TODO: Change after coupons have been implemented
    const activeCoupons = true;
    const selectBasketElementId = 'CartModal__select-basket';
    const productListHeight = `${this.state.rightColumnHeight}px`;

    return (
      <>
        <Row>
          {showLeftColumn && (
            <Col
              xs="12"
              md="6"
              className={classNames(
                'CartModal__column CartModal__left-column',
                {
                  'CartModal__left-column-with-coupons-compensation':
                    activeCoupons,
                }
              )}
            >
              <h4 className="CartModal__title">{this.getNewProductTitle()}</h4>
              <div className="CartModal__column-inner">
                {this.getNewProductContent()}
                {showProductListingColumn && (
                  <div
                    className="CartModal__product-list--with-images"
                    style={{ maxHeight: productListHeight }}
                  >
                    {cart.products.map((product) => (
                      <CartProductRow key={product.id} product={product} />
                    ))}
                  </div>
                )}
              </div>
            </Col>
          )}
          {showProductListingColumn && (
            <CartProductListing
              activeCoupons={activeCoupons}
              showProductListingColumn={showProductListingColumn}
              productListHeight={productListHeight}
            />
          )}
          <Col
            xs={12}
            md={leftColumnActive ? 6 : 12}
            className="CartModal__right-column CartModal__column"
          >
            {showHigherButton && checkoutButton}
            <h4 className="CartModal__title">
              <FormattedMessage {...globalTranslations.cartContents} />
            </h4>
            <div
              className="CartModal__column-inner"
              ref={(rightColumn) => (this.rightColumn = rightColumn)}
            >
              <div>
                <span className="CartModal__cart-product-count">
                  {cart && cart.baskets_and_templates.length > 0 && (
                    <FormGroup>
                      <Label for={selectBasketElementId}>
                        <FormattedMessage
                          id="cart.selectShoppingCart"
                          defaultMessage="Select shopping cart"
                        />
                      </Label>
                      <Input
                        type="select"
                        onChange={this.changeBasket}
                        id={selectBasketElementId}
                        value={cart.selected_basket_id}
                      >
                        {cart.baskets_and_templates.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.text}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  )}
                  {(!cart || cart.number_of_products === 0) && (
                    <FormattedMessage
                      {...globalTranslations.cartEmptySentence}
                    />
                  )}
                  {cart && cart.number_of_products > 0 && (
                    <FormattedMessage
                      {...globalTranslations.itemsInCart}
                      values={{
                        itemCount: (
                          <FormattedMessage
                            {...globalTranslations.itemCount}
                            values={{
                              count: cart.number_of_products,
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                </span>
                <div className="CartModal__price-information">
                  {this.getNewProductPriceInfo()}
                </div>
              </div>
              {this.pdfCatalogEnabled() && (
                <ProductPdf
                  activeId={cart.selected_basket_id.toString()}
                  pdfType={ProductPdfType.CART}
                />
              )}
              <div>
                {!showHigherButton && checkoutButton}
                {activeCoupons && (
                  <div className="CartModal__coupon-instructions">
                    <FormattedMessage
                      id="cart.couponInstructions"
                      defaultMessage="Discount coupons may be provided on the checkout page."
                    />
                  </div>
                )}
                {continueShoppingButton}
              </div>
            </div>
          </Col>
        </Row>
        {showLeftColumn &&
          !openedManually &&
          cartStore.state === RequestState.LOADED &&
          this.getAlsoPurchased()}
      </>
    );
  };

  toggleCartModal = () => this.props.cartStore.cartModal.toggle();

  render() {
    const { cartStore, configStore } = this.props;
    const { showImages } = configStore.cart.modal;
    if (!cartStore.cart) {
      return null;
    }

    return (
      <Modal
        className={classNames('CartModal', {
          'CartModal__after-add-to-cart':
            !cartStore.cartModal.openedManually ||
            (showImages && cartStore.cart.products.length > 0),
        })}
        size="lg"
        onOpened={this.handleOpen}
        isOpen={cartStore.cartModal.isOpen}
        toggle={cartStore.cartModal.toggle}
      >
        <div className="CartModal__hanging-buttons">
          <Button onClick={this.toggleCartModal.bind(this)} color="primary">
            <span className="CartModal__close-button">
              <Icon className="CartModal__close-icon" name="times" />
              <FormattedMessage {...globalTranslations.closeTitle} />
            </span>
          </Button>
        </div>
        <ModalBody>{this.getCartContent()}</ModalBody>
      </Modal>
    );
  }
}

CartModal.propTypes = {
  cartStore: modelOf(CartStore).isRequired,
  campaignCodeStore: modelOf(CampaignCodeStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  history: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  location: RouterPropTypes.location.isRequired,
  withTax: PropTypes.bool.isRequired,
};

export default injectIntl(
  withRouter(
    inject((stores) => ({
      cartStore: stores.cartStore,
      campaignCodeStore: stores.campaignCodeStore,
      configStore: stores.configStore,
      sectionStore: stores.sectionStore,
      productStore: stores.productStore,
      uiStore: stores.uiStore,
      analytics: stores.analytics,
      routeService: stores.routeService,
      withTax: stores.accountStore.showPricesWithTax,
    }))(CartModal)
  )
);
