import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes, observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Category from '../../../models/Category';
import RouteService from '../../../services/RouteService';
import Icon from '../../common/Icon';

const NavigationSimpleCategoryGrid = ({ routeService, categories }) => {
  const getIcon = (icon) => {
    return (
      <div className="NavigationSimpleCategoryGrid__icon">
        <Icon name={icon} />
      </div>
    );
  };

  const getCategoryGrid = () => {
    return categories.map((category) => {
      return (
        <div key={category.id} className="NavigationSimpleCategoryGrid__item">
          <Link
            className="CategoryLink"
            to={routeService.getPath(category.path)}
          >
            {category.icon && getIcon(category.icon)}
            <div className="NavigationSimpleCategoryGrid__name">
              {category.navigationName}
            </div>
          </Link>
        </div>
      );
    });
  };

  return (
    <div className="NavigationSimpleCategoryGrid">
      <div className="NavigationSimpleCategoryGrid__header">
        <FormattedMessage
          id="navigation.browseCategories"
          defaultMessage="Browse categories"
        />
      </div>
      <div className="NavigationSimpleCategoryGrid__grid">
        {getCategoryGrid()}
      </div>
    </div>
  );
};

NavigationSimpleCategoryGrid.propTypes = {
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  categories: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(modelOf(Category)).isRequired,
    PropTypes.arrayOf(modelOf(Category)).isRequired,
  ]),
};

export default inject('routeService')(observer(NavigationSimpleCategoryGrid));
