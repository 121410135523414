import loadable from '@loadable/component';
import axios from 'axios';
import classNames from 'classnames';
import * as JsSearch from 'js-search';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  intlShape,
} from 'react-intl';
import { withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { Button, InputGroup } from 'reactstrap';

import Analytics from '../../../analytics/Analytics';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import { LocationContextPropType } from '../../../services/LocationContext';
import RouteService from '../../../services/RouteService';
import AccountStore from '../../../store/AccountStore';
import CategoryStore from '../../../store/CategoryStore';
import ConfigStore from '../../../store/ConfigStore';
import SearchRedirectionStore from '../../../store/SearchRedirectionStore';
import SuggestionStore from '../../../store/SuggestionStore';
import UIStore from '../../../store/UIStore';
import Paths from '../../../types/Paths';
import RequestState from '../../../types/RequestState';
import StoreThemeLayoutTypes from '../../../types/StoreThemeLayoutTypes';
import SuggestionType from '../../../types/SuggestionType';
import { isCrawler } from '../../../util/browser';
import { addParametersToPath, parse } from '../../../util/queryString';
import {
  isNotHandledByReactRouter,
  stripHostIfMatches,
} from '../../../util/url';
import Autosuggest from '../../common/Autosuggest';
import Icon from '../../common/Icon';
import Spinner from '../../loader/Spinner';
import ProductQuickSearchResult from '../../product/ProductQuickSearchResult';

const BarcodeSearch = loadable(() =>
  import(/* webpackChunkName: "navigation" */ '../BarcodeSearch')
);

const DEFAULT_SUGGESTED_PRODUCTS_VISIBLE = 3;
const MAX_SUGGESTED_PRODUCTS_VISIBLE = 10;
const SEARCH_HISTORY_LIMIT = 5;
const LOCALSTORAGE_HISTORY_KEY = 'history';
const TEXT_SEARCH_MIN_CHARACTERS = 1;

const messages = defineMessages({
  searchProducts: {
    id: 'search.searchProducts',
    defaultMessage: 'Search products',
  },
});

const highlightResult = (text, textToHighlight) => {
  const matchAll = ['*'];
  const regexTextToHighlight = matchAll.some((item) => item === textToHighlight)
    ? ''
    : '(' + textToHighlight + ')';

  const regex = new RegExp('(.*)' + regexTextToHighlight + '(.*)', 'i');
  const matches = text.match(regex);

  return matches ? (
    <span>
      {matches[1]}
      <strong>{matches[2]}</strong>
      {matches[3]}
    </span>
  ) : (
    text
  );
};

const getSuggestionValue = (suggestion) => (suggestion ? suggestion.value : '');
const renderSuggestion = (suggestion) => (suggestion ? suggestion.render : '');
const getSectionSuggestions = (section) => (section ? section.suggestions : []);
const renderSectionTitle = (section) =>
  section ? <strong>{section.title}</strong> : '';

const createSuggestionAnalyticsData = (type, value) => ({
  type,
  value,
});

const createSuggestion = (value, render, action, analyticsData) => ({
  value,
  render,
  action,
  analyticsData,
});

@observer
class QuickSearch extends Component {
  constructor(props) {
    super(props);

    this.showRecentSearches = null;
    this.showPopularSearches = null;
    this.listId = 'quick_search_list';
    this.listName = 'Quick Search List';
    this.suggestionCount = DEFAULT_SUGGESTED_PRODUCTS_VISIBLE;
    this.history = null;
    this.doNotTriggerSearch = null;
    this.recentlyArrowNavigated = false;
    this.popularSearches = null;
    this.closeButtonElement = null;
    this.searchButtonElement = null;

    this.state = {
      // TODO: Improve this anti-pattern. Props should not exist in state. Maybe suggestionstore?
      value: this.props.searchTerm || '',
      suggestions: [],
      total: 0,
      searchHistory: [],
      inputFocus: false,
      containerOpen: false,
      key: 0,
    };
  }

  componentDidMount() {
    const { configStore } = this.props;
    const { advancedSearch } = configStore;

    this.showRecentSearches = advancedSearch.recentSearches;
    this.showPopularSearches = advancedSearch.popularSuggestions;
    /**
     * Attach event listeners to click events because onBlur event consumes them.
     * Having onBlur event handler with onClick-handlers, creates a race condition where onBlur event happens
     * just before onClick event resolves causing the event to be consumed.
     */
    this.registerEventListeners();
    this.maybeUpdateSearchValue();
    this.setSuggestionCount();
    this.removeNullsFromHistory();
    this.maybeGetPopularSearches();
    this.loadHistoryFromLocalStorage();
    if (this.checkHistoryArray) {
      this.historyToSuggestions();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { suggestions } = this.state;
    // any change in search field
    if (this.props.location.search !== prevProps.location.search) {
      this.maybeUpdateSearchValue(prevProps.location.search);
    }

    if (prevState.suggestions.length !== suggestions.length) {
      suggestions.length > 0 && this.setState({ containerOpen: true });
      suggestions.length === 0 && this.setState({ containerOpen: false });
    }
  }

  componentWillUnmount() {
    if (this.closeButtonElement) {
      this.closeButtonElement.removeEventListener(
        'touchend',
        this.handleCloseEvent
      );
      this.closeButtonElement.removeEventListener(
        'mousedown',
        this.handleCloseEvent
      );
    }

    if (this.searchButtonElement) {
      this.searchButtonElement.removeEventListener(
        'touchend',
        this.handleSearchEvent
      );
    }
  }

  registerEventListeners = () => {
    if (this.closeButtonElement) {
      this.closeButtonElement.addEventListener(
        'touchend',
        this.handleCloseEvent
      );
      this.closeButtonElement.addEventListener(
        'mousedown',
        this.handleCloseEvent
      );
    }

    if (this.searchButtonElement) {
      this.searchButtonElement.addEventListener(
        'touchend',
        this.handleSearchEvent
      );
      this.searchButtonElement.addEventListener(
        'mousedown',
        this.handleSearchEvent
      );
    }
  };

  handleCloseEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.clearSearch();
    this.onBlur();
  };

  handleSearchEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.onClickFunctions();
    this.onBlur();
  };

  maybeUpdateSearchValue = (prevSearch = '') => {
    const searchText = this.getKeywords();

    if (prevSearch.length > 0 && !searchText) {
      this.reset();
      return;
    }

    if (this.state.value.length === 0 && searchText && searchText.length > 0) {
      this.update(searchText);
    }
  };

  loadHistoryFromLocalStorage = () => {
    const historyFromLocalStorage = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_HISTORY_KEY)
    );
    if (historyFromLocalStorage) {
      this.history = historyFromLocalStorage;
    } else {
      this.history = [];
    }

    this.setState({ searchHistory: this.history });
  };

  saveHistoryToLocalStorage = () => {
    localStorage.setItem(
      LOCALSTORAGE_HISTORY_KEY,
      JSON.stringify(this.history)
    );
  };

  historyToSuggestions = () => {
    const suggestionSections = [];

    if (this.history) {
      const visibleHistory = this.history
        .filter((h) => h !== null)
        .map((h, i) => {
          return createSuggestion(h, this.historySuggestionItem(h, i), () =>
            this.search(h)
          );
        });

      suggestionSections.push({
        title: <FormattedMessage {...globalTranslations.searchHistoryTitle} />,
        suggestions: visibleHistory,
      });
    }

    this.setState({
      searchHistory: suggestionSections,
    });
  };

  handleDeleteClick = (i) => {
    this.doNotTriggerSearch = true;
    this.history.splice(i, 1);
    this.saveHistoryToLocalStorage();
    this.historyToSuggestions();
    this.setState({
      inputFocus: false,
    });
  };

  historySuggestionItem = (value, index) => {
    return (
      <div className="history__result">
        {value}
        <Button
          onClick={this.handleDeleteClick.bind(this, index)}
          className="history__delete-button"
        >
          <span>
            <Icon name="times" />{' '}
          </span>
        </Button>
      </div>
    );
  };

  checkHistoryArray = () => {
    return this.history.every((element) => element === null) ? false : true;
  };

  removeNullsFromHistory = () => {
    if (this.history) {
      this.history = this.history.filter((h) => h !== null);
      this.saveHistoryToLocalStorage();
      this.setState({ searchHistory: this.history });
      this.historyToSuggestions();
    }
  };

  getKeywords = () => {
    if (!this.props.location) {
      return null;
    }
    const queryParams = parse(this.props.location.search);
    return queryParams.keywords;
  };

  update = (search) => {
    this.setState({ value: search });
  };

  onChange = (event, { newValue, method }) => {
    if (this.doNotTriggerSearch) {
      return;
    }
    const { onFilteringChange } = this.props;
    const ignoredMethods = ['enter', 'up', 'down'];

    // Toggle variable if arrow navigation was used. Special check for enter
    // because enter is also considered as a change and causes some problems
    // in onSuggestionSelected.
    if (
      (this.recentlyArrowNavigated && method === 'enter') ||
      method === 'up' ||
      method === 'down'
    ) {
      this.recentlyArrowNavigated = true;
    }

    // Don't change state if user presses enter while hovering a suggestion.
    if (ignoredMethods.includes(method)) {
      return;
    }

    // When backspacing the value away we need to clear the suggestions so
    // the old values suggestions do not distract user.
    if (method === 'type' && !this.validCharacterLength(newValue)) {
      this.setState({
        suggestions: [],
      });
    }

    this.setState(
      {
        value: newValue,
        inputFocus: true,
      },
      () => !this.state.value && this.getFilterText()
    );

    if (onFilteringChange) {
      onFilteringChange(event);
    }
  };

  onFocus = () => {
    this.setState({ inputFocus: true });
    this.props.handleFocus();
  };

  onBlur = () => {
    const { handleBlur, uiStore } = this.props;
    this.setState({ inputFocus: false });
    uiStore.toggleSearch(false);
    handleBlur();
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onClickFunctions();
      this.toggleSearch();
      this.getFilterText();
      this.setState({ inputFocus: false });
    }
  };

  setupCategorySearch() {
    const { categoryStore } = this.props;
    const tokenizer = {
      tokenize(text) {
        return text.split(/\s+/).filter(
          (text) => text // Filter empty tokens
        );
      },
    };
    this.categorySearch = new JsSearch.Search('id');
    this.categorySearch.tokenizer = tokenizer;
    this.categorySearch.indexStrategy =
      new JsSearch.AllSubstringsIndexStrategy();
    this.categorySearch.addIndex('name');
    this.categorySearch.addDocuments(categoryStore.flattenedCategories);
  }

  getMatchingCategories(searchText) {
    // Lazy load categorySearch
    if (!this.categorySearch) {
      this.setupCategorySearch();
    }
    return this.categorySearch.search(searchText).slice(0, 3);
  }

  search = (searchText) => {
    const {
      configStore,
      analytics,
      routeService,
      searchRedirectionStore,
      locationContext,
      productListFilter,
    } = this.props;

    if (searchText === '') {
      return;
    }

    productListFilter && this.getFilterText(true);

    const maybeRedirection = searchRedirectionStore.getRedirection(searchText);

    if (maybeRedirection && !productListFilter) {
      const url = stripHostIfMatches(
        maybeRedirection.link,
        locationContext.host
      );

      const notHandledByReactRouter = isNotHandledByReactRouter(url);
      return notHandledByReactRouter
        ? window.open(url, '_blank', 'noopener')
        : this.navigateTo(url);
    }

    !productListFilter &&
      this.navigateTo(
        routeService.getPath(
          addParametersToPath(Paths.AdvancedSearch, {
            keywords: searchText,
          })
        )
      );

    configStore.analytics.ga4.enabled && analytics.search(searchText);
  };

  navigateTo = (url) => {
    this.props.history.push(url);
  };

  toggleSearch = () => {
    this.props.toggleSearch();
  };

  reset = () => {
    this.setState({ value: '' });
  };

  setSuggestionCount = () => {
    const { configStore } = this.props;
    const count = Number(configStore.productSuggestions.count);

    if (!isNaN(count)) {
      if (count < 0) {
        this.suggestionCount = 0;
      }
      if (count > MAX_SUGGESTED_PRODUCTS_VISIBLE) {
        this.suggestionCount = MAX_SUGGESTED_PRODUCTS_VISIBLE;
      } else {
        this.suggestionCount = count;
      }
      return;
    } else if (configStore.productSuggestions.enabled) {
      this.suggestionCount = DEFAULT_SUGGESTED_PRODUCTS_VISIBLE;
      return;
    }

    this.suggestionCount = 0;
  };

  onSuggestionSelected = (event, { suggestion, method }) => {
    if (this.doNotTriggerSearch) {
      return;
    }
    // We need to prevent default events, because we do not wan't to trigger our
    // custom onKeyPress handler.
    event.preventDefault();

    // Prevents search from changing to suggestion value/action,
    // while hovering a suggestion and pressing enter.
    // Instead performs a regular search on enter press.
    if (method === 'enter' && !this.recentlyArrowNavigated) {
      this.onClickFunctions();
      this.toggleSearch();
      return;
    }

    if (suggestion.analyticsData) {
      this.sendSuggestionSelection(
        this.state.value,
        suggestion.analyticsData.type,
        suggestion.analyticsData.value
      );
    }
    if (suggestion.action) {
      suggestion.action();
    }

    this.recentlyArrowNavigated = false;
  };

  sendSuggestionSelection = (searchText, suggestionType, suggestionValue) => {
    const { analytics } = this.props;
    analytics.suggestionSelection(searchText, suggestionType, suggestionValue);
  };

  updateSuggestions = (searchText, products, suggestions, total) => {
    const {
      configStore,
      uiStore,
      analytics,
      intl,
      routeService,
      searchRedirectionStore,
    } = this.props;

    const totalResults = total ? total : this.state.total;

    const suggestionSections = [];

    const maybeRedirection = searchRedirectionStore.getRedirection(searchText);

    if (!maybeRedirection && suggestions.length > 0) {
      suggestionSections.push({
        title: <FormattedMessage {...globalTranslations.suggestionsTitle} />,
        suggestions: suggestions.map((suggestion) =>
          createSuggestion(
            suggestion.text,
            highlightResult(suggestion.text, searchText),
            () => {
              this.search(suggestion.text);
              this.setState({ value: suggestion.text });
            },
            createSuggestionAnalyticsData(
              SuggestionType.SUGGESTION,
              suggestion.id
            )
          )
        ),
      });
    }

    const matchingCategories = configStore.advancedSearch.enabled
      ? this.getMatchingCategories(searchText)
      : [];
    if (!maybeRedirection && matchingCategories.length > 0) {
      suggestionSections.push({
        title: <FormattedMessage {...globalTranslations.categoriesTitle} />,
        suggestions: matchingCategories.map((category) =>
          createSuggestion(
            category.name,
            highlightResult(category.name, searchText),
            () => {
              this.navigateTo(routeService.getCategoryPath(category));
              this.reset();
              this.toggleSearch();
            },
            createSuggestionAnalyticsData(SuggestionType.CATEGORY, category.id)
          )
        ),
      });
    }

    if (!maybeRedirection && products.length > 0) {
      const productSuggestions = products.map((product, index) => {
        const position = index + 1;
        return createSuggestion(
          product.multiproduct_title || product.name,
          <ProductQuickSearchResult
            product={product}
            position={position}
            listName={this.listName}
            hideDescription={uiStore.isMobile || this.suggestionCount > 4}
          />,
          () => {
            this.sendProductClick(position, product);

            this.navigateTo(routeService.getCanonicalProductPath(product));
            this.reset();
            this.toggleSearch();
          },
          createSuggestionAnalyticsData(SuggestionType.PRODUCT, product.id)
        );
      });
      if (totalResults > this.suggestionCount) {
        productSuggestions.push(
          createSuggestion(
            this.state.value,
            <Button
              color="primary"
              aria-label={intl.formatMessage(
                globalTranslations.showAllProductsSentence
              )}
            >
              <span className="QuickSearch__show-all-products">
                <FormattedMessage
                  {...globalTranslations.showAllProductsSentence}
                />
                {` (${totalResults})`}
              </span>
            </Button>,
            () => {
              this.onClickFunctions();
              this.toggleSearch();
            }
          )
        );
      }
      suggestionSections.push({
        title: <FormattedMessage {...globalTranslations.productsTitle} />,
        suggestions: productSuggestions,
      });
    }

    if (
      !maybeRedirection &&
      suggestionSections.length === 0 &&
      configStore.siteConfig.isWebStore
    ) {
      suggestionSections.unshift({
        title: (
          <FormattedMessage {...globalTranslations.searchNoResultsSentence} />
        ),
        suggestions: [
          createSuggestion(
            searchText,
            <FormattedMessage
              {...globalTranslations.searchTryAnotherSentence}
            />,
            () => this.setState({ value: '' })
          ),
        ],
      });
    }

    !configStore.analytics.ga4.enabled &&
      analytics.quickSearch(searchText.toLowerCase(), {
        suggestionCount: suggestions.length,
        categoryCount: matchingCategories.length,
        productCount: products.length,
        count: suggestions.length + matchingCategories.length + products.length,
      });

    this.setState({
      suggestions: suggestionSections,
      total: totalResults,
    });
  };

  shouldRenderSuggestions = () => {
    const { inputFocus } = this.state;

    return !(
      !this.showRecentSearches &&
      !this.showPopularSearches &&
      !this.validCharacterLength() &&
      !inputFocus
    );
  };

  renderCustomInputComponent = (inputProps) => (
    <input {...inputProps} value={this.state.value} />
  );

  maybeGetPopularSearches = () => {
    const { suggestionStore } = this.props;
    if (this.showPopularSearches && !this.popularSearches) {
      suggestionStore.getPopularSearches().then((popularSearches) => {
        this.popularSearches = {
          title: (
            <FormattedMessage {...globalTranslations.popularSearchesTitle} />
          ),
          suggestions: popularSearches.map((keyword) =>
            createSuggestion(keyword, keyword, () => this.search(keyword), {})
          ),
        };

        this.setState({ popularSearches: this.popularSearches });
      });
    }
  };

  onSuggestionsFetchRequested = debounce(
    ({ value }) => {
      const { suggestionStore, productListFilter } = this.props;
      if (productListFilter || !this.validCharacterLength()) {
        return null;
      }

      suggestionStore
        .suggest(value, this.suggestionCount)
        .then(({ products, suggestions, total }) => {
          this.updateSuggestions(value, products, suggestions, total);
          this.sendProductImpressions(products);
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            console.error(e);
          }
        });
    },
    300,
    {
      leading: false,
      trailing: true,
    }
  );

  onSuggestionsClearRequested = () => {
    if (this.doNotTriggerSearch) {
      return;
    }

    this.setState({
      inputFocus: false,
      suggestions: [],
    });
  };

  sendProductClick = (position, product) => {
    const { analytics } = this.props;
    analytics.productClick({
      listName: this.listName,
      productList: [
        {
          product,
          position,
        },
      ],
      listId: this.listId,
    });
  };

  sendProductImpressions = (products) => {
    const { analytics } = this.props;
    const productList = products.map((product, index) => ({
      product,
      position: index + 1,
    }));
    analytics.productImpressions(this.listName, productList);
  };

  // Input searches to localstorage
  addSearchToHistory = (searchText) => {
    if (searchText.trim() === '') {
      return;
    }

    if (this.history.includes(searchText.trim())) {
      return;
    }

    const isHistoryMaxed = this.history.length === SEARCH_HISTORY_LIMIT;

    if (isHistoryMaxed) {
      this.history.pop();
    }

    this.history.unshift(searchText);
    this.saveHistoryToLocalStorage();
    this.historyToSuggestions();
  };

  clearSearch = () => {
    const { productListFilter } = this.props;
    this.clearNavigationBarSearch();
    productListFilter && this.clearProductListFilter();
  };

  clearNavigationBarSearch = () => {
    const { key } = this.state;
    this.setState({
      key: key + 1,
      suggestions: [],
      value: '',
    });
  };

  clearProductListFilter = () => {
    this.props.clearProductListFilter('');
    this.setState({ value: '' });
  };

  onClickFunctions = () => {
    const { value, key } = this.state;
    this.search(value);
    this.addSearchToHistory(value);
    this.setState({
      key: key + 1,
    });
  };

  getFilterText = () => {
    const { productListFilter, onProductListFiltering } = this.props;
    //TODO: Needs to be refactored with ProductListFilter-component to be more generic
    if (productListFilter && onProductListFiltering) {
      onProductListFiltering(this.state.value);
    }
  };

  getInitialSearchSuggestions = () => {
    const { searchHistory, popularSearches, inputFocus } = this.state;

    if (!inputFocus) {
      return [];
    }

    let suggestions = [];
    if (
      this.showRecentSearches &&
      searchHistory &&
      searchHistory[0].suggestions.length
    ) {
      suggestions = suggestions.concat(searchHistory);
    }

    if (
      this.showPopularSearches &&
      popularSearches &&
      popularSearches.suggestions.length
    ) {
      suggestions = suggestions.concat(popularSearches);
    }
    return suggestions;
  };

  validCharacterLength = (preValue = null) => {
    const { value } = this.state;
    if (preValue) {
      return preValue.trim().length > TEXT_SEARCH_MIN_CHARACTERS;
    }
    return !!value && value.trim().length > TEXT_SEARCH_MIN_CHARACTERS;
  };

  getDeterminedSuggestions = () => {
    const { productListFilter } = this.props;
    const { suggestions, inputFocus } = this.state;

    if (!inputFocus) {
      return [];
    }

    const maybeInitialSuggestions =
      !productListFilter && this.getInitialSearchSuggestions();

    if (!this.validCharacterLength() && maybeInitialSuggestions.length) {
      return maybeInitialSuggestions;
    }

    return !productListFilter ? suggestions : [];
  };

  renderBarcodeReader = () => {
    if (
      isCrawler() ||
      typeof navigator === 'undefined' ||
      !navigator?.mediaDevices
    ) {
      return;
    }

    const { accountStore, configStore, uiStore } = this.props;

    return (
      !uiStore.isDesktop &&
      accountStore.isRetailer &&
      configStore.barcodeSearch.enabled && <BarcodeSearch />
    );
  };

  getStoreTheme = () => {
    const { configStore } = this.props;

    const themeSelection = configStore.layoutStoreThemeSelection;

    const isRounded =
      themeSelection === StoreThemeLayoutTypes.FULLY_ROUNDED_EDGES;
    const isBox = themeSelection === StoreThemeLayoutTypes.BOX;

    return { isRounded, isBox };
  };

  render() {
    const { value, inputFocus, key } = this.state;
    const {
      uiStore,
      suggestionStore,
      productListFilter,
      placeholder,
      buttonText,
      buttonClassName,
      className,
      intl,
      quickSearchId,
      hideButtonText,
      hideSearchButton,
    } = this.props;

    this.doNotTriggerSearch = false;

    const placeholderText =
      placeholder || intl.formatMessage(messages.searchProducts);

    const searchButtonText = buttonText || (
      <FormattedMessage {...globalTranslations.search} />
    );

    const inputProps = {
      placeholder: placeholderText,
      value,
      onChange: this.onChange,
      onKeyPress: this.handleKeyPress,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      className: 'form-control',
      autoCapitalize: 'none',
      maxLength: '80',
      type: 'search',
      name: 'search',
    };

    const determinedSuggestions = this.getDeterminedSuggestions();
    const ifProductListFilterValue = productListFilter && value;
    const { isRounded, isBox } = this.getStoreTheme();

    return (
      <InputGroup
        className={classNames('QuickSearch', className, {
          'QuickSearch--rounded': isRounded,
          'QuickSearch--box': isBox,
        })}
      >
        <Autosuggest
          key={key}
          id={quickSearchId}
          suggestions={determinedSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          focusInputOnSuggestionClick={false}
          multiSection
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          renderInputComponent={
            productListFilter && this.renderCustomInputComponent
          }
        />
        <div className="QuickSearch__group-addons">
          {!productListFilter &&
            suggestionStore.state === RequestState.LOADING && (
              <div className="QuickSearch__spinner">
                <Spinner />
              </div>
            )}
          <Button
            color={''}
            aria-label={intl.formatMessage(globalTranslations.clearTitle)}
            className={classNames('QuickSearch__clear', {
              'QuickSearch__clear--visible': value,
            })}
            innerRef={(ref) => (this.closeButtonElement = ref)}
          >
            <Icon name="close" />
          </Button>
          {!hideSearchButton && (
            <>
              {!productListFilter && this.renderBarcodeReader()}
              <Button
                color={!productListFilter ? 'primary' : ''}
                aria-label={intl.formatMessage(globalTranslations.search)}
                className={classNames(
                  'QuickSearch__filter-button',
                  buttonClassName,
                  {
                    'QuickSearch__filter-button--active':
                      ifProductListFilterValue,
                    'QuickSearch__suggestion-container--loaded':
                      !productListFilter &&
                      inputFocus &&
                      determinedSuggestions.length,
                  }
                )}
                innerRef={(ref) => (this.searchButtonElement = ref)}
              >
                <Icon name="search" />
                {uiStore.isDesktop && !uiStore.isLG && !hideButtonText && (
                  <span className="QuickSearch__filter-button-text">
                    {searchButtonText}
                  </span>
                )}
              </Button>
            </>
          )}
        </div>
      </InputGroup>
    );
  }
}

QuickSearch.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  suggestionStore: modelOf(SuggestionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  searchRedirectionStore: modelOf(SearchRedirectionStore).isRequired,
  history: RouterPropTypes.history.isRequired,
  location: RouterPropTypes.location.isRequired,
  intl: intlShape.isRequired,
  locationContext: LocationContextPropType.isRequired,
  quickSearchId: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  searchTerm: PropTypes.string,
  hideButtonText: PropTypes.bool,
  hideSearchButton: PropTypes.bool,
  productListFilter: PropTypes.bool,
  clearProductListFilter: PropTypes.func,
  onProductListFiltering: PropTypes.func,
  onFilteringChange: PropTypes.func,
  buttonText: PropTypes.node,
};

QuickSearch.defaultProps = {
  quickSearchId: 'QuickSearch__Autosuggest',
};

export default inject(
  'accountStore',
  'categoryStore',
  'configStore',
  'searchRedirectionStore',
  'suggestionStore',
  'uiStore',
  'analytics',
  'routeService',
  'locationContext'
)(withRouter(injectIntl(QuickSearch)));
