import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';

import { modelOf } from '../../../prop-types';
import ProductPriceInfo from '../../../models/ProductPriceInfo';
import Price from '../Price';
import ConfigStore from '../../../store/ConfigStore';
import AccountStore from '../../../store/AccountStore';
import QuantityDiscount from '../../../models/QuantityDiscount';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
export class ProductPrice extends Component {
  renderPriceWithDiscount = (
    isDiscount,
    showUnit,
    showTaxExcludedInfo,
    showTaxIncludedInfo
  ) => {
    const {
      priceInfo,
      withTax,
      product,
      hidePrice,
      className,
      isSecondaryPrice,
      isMultiProductPicker,
    } = this.props;
    const productPrice = priceInfo.getPrice(withTax);

    return (
      !hidePrice &&
      priceInfo && (
        <div>
          <Price
            price={productPrice}
            discount={isDiscount && !isSecondaryPrice}
            showTaxExcludedInfo={showTaxExcludedInfo}
            showTaxIncludedInfo={showTaxIncludedInfo}
            fontSize={isSecondaryPrice}
            isMultiProductPicker={isMultiProductPicker}
            suffix={
              product &&
              showUnit &&
              !isSecondaryPrice && (
                <span className="ProductPrice__stock-unit">
                  {' '}
                  / {product.stock_unit}
                </span>
              )
            }
            className={className}
          />
        </div>
      )
    );
  };

  renderPriceNormal = (
    isDiscount,
    showTaxExcludedInfo,
    showTaxIncludedInfo
  ) => {
    const {
      activeProductId,
      product,
      productBundle,
      priceInfo,
      withTax,
      hideDiscount,
      className,
      isSecondaryPrice,
      isMultiProductPicker,
    } = this.props;

    let productPrice =
      productBundle && product && product.package_size
        ? product.getPackagePrice(withTax, activeProductId)
        : priceInfo.getNormalPrice(withTax);

    return (
      !hideDiscount &&
      priceInfo &&
      isDiscount &&
      !isSecondaryPrice && (
        <div>
          <Price
            price={productPrice}
            muted
            showTaxExcludedInfo={showTaxExcludedInfo}
            showTaxIncludedInfo={showTaxIncludedInfo}
            fontSize={isSecondaryPrice}
            isMultiProductPicker={isMultiProductPicker}
            prefix={
              <span>
                <FormattedMessage {...globalTranslations.RRP} />{' '}
              </span>
            }
            className={className}
          />
        </div>
      )
    );
  };

  render() {
    const {
      priceInfo,
      withTax,
      forRetailer,
      alwaysShowUnit,
      product,
      hideTaxExcludedInfo,
      showTaxIncludedInfo,
    } = this.props;

    if (!priceInfo) {
      return null;
    }

    const isDiscount = priceInfo && priceInfo.is_discount;
    const showUnit =
      (product && product.sellInPackage) || forRetailer || alwaysShowUnit;

    const showTaxExcludedInfo = !withTax && !hideTaxExcludedInfo;

    return (
      <div className="ProductPrice">
        {this.renderPriceWithDiscount(
          isDiscount,
          showUnit,
          showTaxExcludedInfo,
          showTaxIncludedInfo
        )}
        {this.renderPriceNormal(
          isDiscount,
          showTaxExcludedInfo,
          showTaxIncludedInfo
        )}
      </div>
    );
  }
}

ProductPrice.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  alwaysShowUnit: PropTypes.bool.isRequired,
  forRetailer: PropTypes.bool.isRequired,
  withTax: PropTypes.bool.isRequired,
  activeProductId: PropTypes.string,
  className: PropTypes.string,
  hideDiscount: PropTypes.bool,
  hidePrice: PropTypes.bool,
  hideTaxExcludedInfo: PropTypes.bool,
  isMultiProductPicker: PropTypes.bool,
  isSecondaryPrice: PropTypes.bool,
  priceInfo: PropTypes.oneOfType([
    modelOf(ProductPriceInfo),
    modelOf(QuantityDiscount),
  ]),
  // Both Products and ChildProducts are allowed.
  product: PropTypes.object,
  productBundle: PropTypes.bool,
  showTaxIncludedInfo: PropTypes.bool,
};

ProductPrice.defaultProps = {
  hideTaxExcludedInfo: false,
  productBundle: false,
};

export default inject((stores, props) => ({
  configStore: stores.configStore,
  accountStore: stores.accountStore,
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax,
  forRetailer: stores.accountStore.isRetailer,
  alwaysShowUnit: stores.configStore.product.alwaysShowUnit,
}))(ProductPrice);
