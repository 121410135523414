import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';

import Price from '../../product/Price';
import { roundWithPrecision } from '../../../util/number';

@observer
export class ProductPackageSize extends Component {
  render() {
    const { currencyStore, product, withTax } = this.props;
    const showTaxExcludedInfo = !withTax;
    const price = roundWithPrecision(
      product.package_size *
        roundWithPrecision(
          product.getPrice(withTax),
          currencyStore.activeCurrency.precision
        ),
      currencyStore.activeCurrency.precision
    );

    return (
      <div className="ProductPackageSize">
        <FormattedMessage
          id="product.packageSizeDescription"
          defaultMessage="{packageSizeSentence}: 1 {packageUnitName} = {packageSize} {stockUnit} ({packagePrice} / {packageUnitName})"
          values={{
            packageSizeSentence: (
              <FormattedMessage
                id="product.packageSizeSentence"
                defaultMessage="Package size"
              />
            ),
            packageUnitName: product.package_unit_name,
            packageSize: product.package_size,
            stockUnit: product.stock_unit,
            packagePrice: (
              <Price price={price} showTaxExcludedInfo={showTaxExcludedInfo} />
            )
          }}
        />
      </div>
    );
  }
}

ProductPackageSize.propTypes = {
  product: PropTypes.object.isRequired,
  withTax: PropTypes.bool.isRequired
};

export default inject((stores, props) => ({
  currencyStore: stores.currencyStore,
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax
}))(ProductPackageSize);
