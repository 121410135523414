import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';

import globalTranslations from '../../../i18n/globalTranslations';

class CouponExpiry extends Component {
  render() {
    const { date } = this.props;

    return (
      <span className="CouponExpiry">
        <FormattedMessage
          {...globalTranslations.couponValidUntilSentence}
          values={{
            offerExpiry: DateTime.fromISO(date).toLocaleString()
          }}
        />
      </span>
    );
  }
}

CouponExpiry.propTypes = {
  // ISO 8601 date
  date: PropTypes.string
};

export default CouponExpiry;
