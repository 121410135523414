import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import globalTranslations from '../../../i18n/globalTranslations';
import Price from '../Price';
import { modelOf } from '../../../prop-types';
import QuantityDiscounts from '../../../models/QuantityDiscounts';

export const ProductQuantityDiscounts = ({
  withTax,
  quantityDiscounts,
  stockUnit,
}) => {
  const discounts = quantityDiscounts.getLevels();

  if (!discounts) {
    return null;
  }

  const getCellValue = (value, center) => {
    return {
      value: value,
      center: center,
    };
  };

  const renderHeaderRow = (headers) => {
    return (
      <thead className="thead-light">
        <tr className="ProductQuantityDiscounts__discount-table-header">
          {headers.map((header, index) => {
            return (
              <th
                className={
                  header.center
                    ? 'ProductQuantityDiscounts__discount-table-header-center'
                    : ''
                }
                key={`${header.value}_${index}`}
              >
                {header.value}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderDiscountRow = () => {
    return (
      <tbody>
        {discounts.map((discount, index) => {
          return (
            <tr
              className="ProductQuantityDiscounts__discount-table-row"
              key={index}
            >
              <td>
                {discount.quantity} {stockUnit}
              </td>
              <td className={'ProductQuantityDiscounts__discount-table-cell'}>
                <Price
                  price={discount.price.getPrice(withTax)}
                  discount={discount.quantity !== 1}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const renderDiscountTable = () => {
    const showTaxExcludedInfo = !withTax;
    const headerRow = [];

    headerRow.push(
      getCellValue(
        <FormattedMessage {...globalTranslations.quantityTitle} />,
        false
      )
    );

    headerRow.push(
      getCellValue(
        <Fragment>
          <FormattedMessage {...globalTranslations.unitPriceTitle} />
          {showTaxExcludedInfo && (
            <Fragment>
              {' '}
              <FormattedMessage {...globalTranslations.taxExcluded} />
            </Fragment>
          )}
        </Fragment>,
        false
      )
    );

    return (
      <Table
        size="sm"
        bordered
        className="ProductQuantityDiscounts__discount-table"
      >
        {renderHeaderRow(headerRow)}
        {renderDiscountRow()}
      </Table>
    );
  };

  return (
    <div className="ProductQuantityDiscounts">
      <div className="ProductQuantityDiscounts__description">
        <FormattedMessage
          id="discount.quantityDescription"
          defaultMessage="Order more and save money!"
        />
      </div>
      <div className="ProductQuantityDiscounts__discount">
        {renderDiscountTable()}
      </div>
    </div>
  );
};

ProductQuantityDiscounts.propTypes = {
  quantityDiscounts: modelOf(QuantityDiscounts).isRequired,
  isMulti: PropTypes.bool.isRequired,
  withTax: PropTypes.bool.isRequired,
  stockUnit: PropTypes.string,
};

export default inject((stores, props) => ({
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax,
}))(observer(ProductQuantityDiscounts));
