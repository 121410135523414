import { types } from 'mobx-state-tree';

const StoreThemeLayoutTypes = {
  SOFTLY_ROUNDED_CORNERS: 'SOFTLY_ROUNDED_CORNERS',
  FULLY_ROUNDED_EDGES: 'FULLY_ROUNDED_EDGES',
  BOX: 'BOX'
};

export default StoreThemeLayoutTypes;

export const AcceptableTermsTypeType = types.enumeration(
  'StoreThemeLayoutTypes',
  Object.values(StoreThemeLayoutTypes)
);
