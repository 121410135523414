import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Input, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import classNames from 'classnames';

import ProductExtraInfo from '../ProductExtraInfo';
import ProductAvailabilityText from '../ProductAvailabilityText';
import ProductPackageSize from '../ProductPackageSize';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ProductPrice from '../ProductPrice';
import ProductImage from '../ProductImage';
import ProductImageModel from '../../../models/ProductImage';
import ConfigStore from '../../../store/ConfigStore';
import { DEFAULT_MAX_QUANTITY } from '../ProductAddToCart';
import ProductAvailabilityType from '../../../types/ProductAvailabilityType';
import Icon from '../../common/Icon';
import UIStore from '../../../store/UIStore';

function createCartProduct(id, qty) {
  return { id, qty };
}

@observer
class ProductMatrixCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupOpen: false,
      quantity: '',
    };
  }

  openPopover = () =>
    this.setState({
      popupOpen: true,
    });

  closePopover = () =>
    this.setState({
      popupOpen: false,
    });

  handleProductQuantity = (e) => {
    const { product, handleProductQuantity } = this.props;

    const maxQuantity = this.getMaxQuantity();
    const newQuantity = e.target.value
      ? this.getQuantity(Number(e.target.value), maxQuantity)
      : '';

    handleProductQuantity(createCartProduct(product.id, newQuantity));

    // Empty string because console throws a warning about controlled inputs that
    // cannot be null.
    // For future improvement, parent component selectedProducts should provide
    // the quantity via prop.
    this.setState({
      quantity: newQuantity,
    });
  };

  getQuantity = (qty, maxQuantity) => {
    const { product } = this.props;
    const freeQuantity = product.getFreeQuantity(product.id);

    const cannotBeOrdered =
      freeQuantity <= 0 && !product.canBeOrderedOutOfStock && qty < 0;
    const canBeOrdered = freeQuantity > 0 && qty > 0 && qty <= maxQuantity;
    const exceedsMaxQuantity = qty >= maxQuantity;
    const nonNegativeQuantity = qty > 0 && qty;

    if (cannotBeOrdered) {
      return 0;
    }

    if (canBeOrdered) {
      return nonNegativeQuantity;
    }

    return exceedsMaxQuantity ? maxQuantity : nonNegativeQuantity;
  };

  getMaxByPackage = (product) =>
    product.sellInPackage
      ? Math.floor(product.getFreeQuantity(product.id) / product.package_size) *
        product.package_size
      : product.getFreeQuantity(product.id);

  getMaxQuantity = () => {
    const { accountStore, configStore, product } = this.props;

    const maxAccountQuantity =
      accountStore.account && accountStore.account.max_product_quantity > 0
        ? accountStore.account.max_product_quantity
        : DEFAULT_MAX_QUANTITY;

    const maxBackorderQuantity =
      product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER
        ? configStore.product.backorderLimit > 0
          ? configStore.product.backorderLimit
          : DEFAULT_MAX_QUANTITY
        : DEFAULT_MAX_QUANTITY;

    const maxNormalProductQuantity =
      product.availability_type !== ProductAvailabilityType.ALLOW_BACKORDER
        ? product.canBeOrderedOutOfStock
          ? DEFAULT_MAX_QUANTITY
          : this.getMaxByPackage(product)
        : DEFAULT_MAX_QUANTITY;

    return Math.min(
      maxAccountQuantity,
      maxBackorderQuantity,
      maxNormalProductQuantity,
      DEFAULT_MAX_QUANTITY
    );
  };

  getUnavailableProduct = () => (
    <div
      key={uniqueId('Cell-')}
      className="ProductMatrixCell__unavailable-product"
    >
      <div className="ProductMatrixCell__line" />
    </div>
  );

  getCellStyle = () => {
    const { uiStore, cellCount } = this.props;
    let width = 100;

    if ((cellCount > 5 && !uiStore.isDesktop) || cellCount > 10) {
      width = 78;
    }

    return {
      width,
    };
  };

  getNoProduct = () => {
    return (
      <div
        key={uniqueId('Cell-')}
        className="ProductMatrixCell__no-product"
        style={this.getCellStyle()}
      >
        <Icon name="ban" />
      </div>
    );
  };

  createProductImage = (url) => {
    return url
      ? ProductImageModel.create({
          id: 0,
          product_id: '',
          sizes: {
            small: url,
          },
        })
      : null;
  };

  renderPopover = () => {
    const { product, accountStore } = this.props;

    const productImage = product.images.find(
      (image) => image.product_id === product.id
    );

    const withTax = !accountStore.showPricesWithTax;

    return (
      <Popover
        target={`ProductInfo-${product.id}`}
        isOpen={this.state.popupOpen}
        placement="top"
        boundariesElement="viewport"
      >
        <PopoverHeader>
          <FormattedMessage
            id="product.informationTitle"
            defaultMessage="Product information"
          />
        </PopoverHeader>
        <PopoverBody>
          <div className="ProductMatrixCell__popover-image_info">
            {productImage && productImage.sizes.small && (
              <ProductImage
                product={product}
                productImage={this.createProductImage(productImage.sizes.small)}
                size="small"
                forceAspectRatio={false}
              />
            )}
            <ProductExtraInfo product={product} singleColumn={true} />
          </div>
          <ProductPrice withTax={withTax} priceInfo={product.price_info} />
          <ProductAvailabilityText
            availabilityHtml={product.availability_html}
          />
          {product.sellInPackage && (
            <ProductPackageSize product={product} withTax={withTax} />
          )}
        </PopoverBody>
      </Popover>
    );
  };

  render() {
    const { product, noPopover, className } = this.props;
    const { quantity } = this.state;

    if (!product) {
      return this.getNoProduct();
    }

    if (!product.available_online) {
      return this.getUnavailableProduct();
    }

    return (
      <div
        className={classNames('ProductMatrixCell', className)}
        style={this.getCellStyle()}
      >
        <Input
          id={`ProductInfo-${product.id}`}
          className="ProductMatrixCell__input"
          type="number"
          name={product.id}
          value={quantity}
          onFocus={this.openPopover}
          onBlur={this.closePopover}
          onChange={this.handleProductQuantity}
        />
        {!noPopover && this.renderPopover()}
      </div>
    );
  }
}

ProductMatrixCell.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore),
  handleProductQuantity: PropTypes.func.isRequired,
  cellCount: PropTypes.number.isRequired,
  className: PropTypes.string,
  noPopover: PropTypes.bool,
  product: PropTypes.object,
};

export default inject(
  'accountStore',
  'configStore',
  'uiStore'
)(ProductMatrixCell);
